import React, { Component } from "react";
import axios from "axios";
import "../css/tabularReport.css"
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import WorkInProgress from "./work_in_progress";
import WithNavigate  from "./withNavigate";
import NewFilterCompo from "./NewFilterCompo";
import "../css/listcompo.css";

class TabularReportCompo extends Component {
    state = {
        loca: this.props.loca,
        id: '0',
        reportlist: {},
        recordColumn: [],
        singleColumn: [],
        page_error: false,
        error: "",
        page_message: false,
        message: "",
        nm_list: [],
        col_mn:[],
        filtarray:[],
        tabName:"",
        timeline:"",
        showbtn:false,
        showFill:false,
        showCols:[]
    }

    constructor(props) {
        super(props);
        this.downloadImage = this.downloadImage.bind(this);
    }

    componentDidMount() {
        this.updateSearchParams()
     
        this.getInitialData()
       
    }

    getInitialData=()=>{
      
      let recordId = this.state.id;
      console.log(recordId);
      if (recordId !== "" || recordId !== "none" || recordId !== undefined) {
        var token = localStorage.getItem("token");
        console.log(token);
        
        axios
            .post(this.state.loca + "/loom/get/tabular/report/" + recordId,{id:""} ,{
                headers: {
                    authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                const rcd = res.data;
                console.log("response: ", rcd);
                if (rcd !== "") {
                    if ("Error" in rcd) {
                        this.setState({
                            loading: false,
                            page_error: true,
                            error: rcd.Error,
                        });
                    } else {
                        var nm_list = [];
                        let col=[]
                  /*       for (var i = 0; i < rcd.tabularRecord[0]?.recordColumn[0]?.record.length; i++) {
                            nm_list.push(rcd.tabularRecord[0].recordColumn[0].record[i].name);
                            col.push(rcd.tabularRecord[0].recordColumn[0].record[i].id)
                        } */
                            for (var i = 0; i < rcd.headersColumn.length; i++) {
                              nm_list.push(rcd.headersColumn[i].label);
                              col.push({id:rcd.headersColumn[i].id ,name:rcd.headersColumn[i].name})
                          }

                          let pt=col.filter((co) =>co.id===rcd.singleColumn.id)
                          if(!pt)
                            col.push({id:rcd.headersColumn[i].id ,name:rcd.headersColumn[i].name});

                          for(let y=0;y< rcd.filter.filter.length;y++){
                             let co=rcd.filter.filter[y].co
                             console.log("filterco" ,co);
                             
                             let obj=col.filter((cl)=>cl.name==co)
                             console.log("kkkkkk" ,obj);
                             
                             if(obj?.length==0){
                              col.push({id:"",name:co});
                             }
                       
                          }

                          console.log(nm_list);
                          console.log(col);
                          
                          
                        this.setState({
                            reportlist: rcd,
                            recordColumn: rcd.tabularRecord[0].recordColumn,
                            singleColumn: rcd.tabularRecord[1].singleColumn,
                            nm_list: nm_list,
                            filtarray: rcd.filter.filter,
                            timeline:rcd.filter.timeline,
                            tabName:rcd.tabelName,
                            showCols:col
                        });
                    }
                }
            },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                });
    }
    }

    getAllCOlumns=()=> {
        console.log("filterItemIncome");
        var token = localStorage.getItem("token");
        axios
          .get(this.state.loca + "/loom/get/allcloumns/" +this.state.tabName , {
            headers: {
              authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          })
          .then(
            (resp) => {
              let res = resp.data;
    
              res.columnRecords.unshift({
                id: "0",
                label: "None",
                name: "none",
                userTable: "false",
              });
              console.log("records: ", res);
              let rtt=[];
              rtt.push({
                id: "0",
                label: "None",
                name: "none",
                userTable: "false",
              });
              console.log(this.state.showCols);
              
             
                for(let t=0;t<this.state.showCols.length;t++){
                    for(let k=0;k<res.columnRecords.length;k++){
                        if(res.columnRecords[k].id==this.state.showCols[t].id || res.columnRecords[k].name==this.state.showCols[t].name){
                            rtt.push(res.columnRecords[k])
                        }
                    }

                }
                
              
              this.setState({ col_mn: rtt });
            },
            (error) => {
              this.props.showErrorCompo();
              console.log(error);
            }
          );
        this.setState({ showFill: !this.state.showFill });
        this.setState({ showbtn: !this.state.showbtn });
      }
     updateSearchParams=(prev)=>{  
    
        let newLocation =this.props.location;
        console.log("newLocation" ,newLocation);
        
        const { location } = this.props;
        
        
        const searchParams = new URLSearchParams(location.search);
        let rid = searchParams.get("rid");
 
        this.setState({
          id: rid
        });   
          }
          static getDerivedStateFromProps(props, state) {  
            const { location } = props;
          
            console.log("Location:", location);
          
            const searchParams = new URLSearchParams(location.search);
            let rid = searchParams.get("rid");
   
            if (rid !== state.id) {
              return {
                id:rid, 
              };
            } 
            return null;
          }
          

    async downloadImage() {
        const dataUrl = await htmlToImage.toJpeg(this.state.reportlist.current, {
            backgroundColor: "white",
            quality: 0.95,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
        });
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "JPEG", 0, 0);
        pdf.save(".pdf");

        // const pdf = new jsPDF("portrait", "pt", "a4"); 
        // const data = await html2canvas(document.querySelector("#pdf"));
        // const img = data.toDataURL(this.state.reportlist.current);  
        // const imgProperties = pdf.getImageProperties("JPEG");
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        // pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        // pdf.save("report.pdf");
    }


    async getChoiceRcd(col_id) {
      var token = localStorage.getItem("token");
      let ck = await axios
        .get(this.state.loca + "/loom/get/choice/" + col_id, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            if ("Error" in res) {
              this.setState({
                loading: false,
                page_error: true,
                error: res.Error,
              });
            } else {
              let chk = res.choiceRecords;
              chk.unshift({ name: "none", value: "None" });
              return chk;
            }
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            this.props.showErrorCompo({ state: { err: err } });
          }
        );
      return ck;
    }

    filtersubmit =()=>{


      let recordId=this.state.id
      let timeline=this.state.timeline
      let filter = this.state.filtarray;
     let  fs = '{"filter":' + JSON.stringify(filter) + "";
      fs += ',"timeline":"' + timeline + '"}';
      var token = localStorage.getItem("token");
      axios
          .post(this.state.loca + "/loom/get/tabular/report/" + recordId,JSON.parse(fs), {
              headers: {
                  authorization: "Bearer " + token,
              },
          })
          .then((res) => {
              const rcd = res.data;
              console.log("response: ", rcd);
              if (rcd !== "") {
                  if ("Error" in rcd) {
                      this.setState({
                          loading: false,
                          page_error: true,
                          error: rcd.Error,
                      });
                  } else {
                      var nm_list = [];
                      let col=[]
                /*       for (var i = 0; i < rcd.tabularRecord[0]?.recordColumn[0]?.record.length; i++) {
                          nm_list.push(rcd.tabularRecord[0].recordColumn[0].record[i].name);
                          col.push(rcd.tabularRecord[0].recordColumn[0].record[i].id)
                      } */
                          for (var i = 0; i < rcd.headersColumn.length; i++) {
                            nm_list.push(rcd.headersColumn[i].label);
                            col.push({id:rcd.headersColumn[i].id ,name:rcd.headersColumn[i].name})
                        }

                        let pt=col.filter((co) =>co.id===rcd.singleColumn.id)
                        if(!pt)
                          col.push({id:rcd.headersColumn[i].id ,name:rcd.headersColumn[i].name});

                        for(let y=0;y< rcd.filter.filter.length;y++){
                           let co=rcd.filter.filter[y].co
                           console.log("filterco" ,co);
                           
                           let obj=col.filter((cl)=>cl.name==co)
                           console.log("kkkkkk" ,obj);
                           
                           if(obj?.length==0){
                            col.push({id:"",name:co});
                           }
                     
                        }

                        console.log(nm_list);
                        console.log(col);
                        
                        
                      this.setState({
                          reportlist: rcd,
                          recordColumn: rcd.tabularRecord[0].recordColumn,
                          singleColumn: rcd.tabularRecord[1].singleColumn,
                          nm_list: nm_list,
                          filtarray: rcd.filter.filter,
                          timeline:rcd.filter.timeline,
                          tabName:rcd.tabelName,
                          showCols:col
                      });
                  }
              }
          },
              (error) => {
                  this.props.showErrorCompo();
                  console.log(error);
              });

      
  
    }
     callfilter = async (filtarray, in_index, col_id)=> {
     
      console.log("callflter in 254");
      
        if (col_id !== -1) {
       
            if (
              filtarray[in_index].ct === "choice" &&
              filtarray[in_index].ch.length === 0
            ) {
              let chc = await this.getChoiceRcd(col_id).then((res) => {
                if (res.length > 0) {
                  filtarray[in_index].ch = res;
                  filtarray[in_index].an = res[0].name;
                } else {
                  filtarray[in_index].ch = [];
                }
              });
            }
          
        }
        this.setState({
          filtarray: filtarray,
        });
      }

      calltimeline(timeline) {
      
          this.setState({
            timeline: timeline,
          });
        
      }

      filterClear=()=>{
        this.getInitialData()

      }
    render() {
        return (
            <div className="pagesetup">
                {this.state.loading === true ? (
                    <WorkInProgress></WorkInProgress>
                ) : (
                    <div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 heading">
                                <label>Tabular Report</label>
                            </div>

                     <div>
                     <i
                        className="fa fa-filter vlpointer"
                        aria-hidden="true"
                        onClick={this.getAllCOlumns}
                      ></i>
                      {this.state.showFill === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Run"
                          aria-hidden="true"
                          onClick={() => {
                            this.filtersubmit(); 
                          }}
                        ></input>
                      )}
                      {this.state.showFill === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Clear"
                          aria-hidden="true"
                          onClick={() => {
                            this.filterClear();
                          }}
                        ></input>
                      )}
                    </div>

                      <div>
                  
                  {this.state.showFill === true && (    <NewFilterCompo
                          /*   showlist={this.state.showlist} */
                          showlist={this.state.showFill}
                            col_mn={this.state.col_mn}
                         /*    col_depend={this.state.column_depend} */
                         col_depend={""} 
                            call_fil={(filtarray, in_index, col_id) =>
                              this.callfilter(filtarray, in_index, col_id)
                            }
                            filtarray={this.state.filtarray}
                            timeline={this.state.timeline}
                            call_tm={(timeline) => this.calltimeline(timeline)}
                            loca={this.props.loca}
                            isMobile={this.props.isMobile}
                            isReference={false}
                          ></NewFilterCompo>)}
                      </div>
                            <div className="col-md-5 bttn">
                                <button
                                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                                    onClick={() => {
                                        this.downloadImage();
                                    }}
                                >
                                    Download
                                </button>
                            </div>
                        </div>

                        <div ref={this.state.reportlist}>
                            <div className="container">
                                <div className="rpt_border rddd">
                                    {this.state.singleColumn.length !== 0 && (
                                        <table>
                                            <thead>
                                                {this.state.singleColumn.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td className="tdddd">{obj.name}</td>
                                                        <td className="tddd1">{obj.value}</td>
                                                    </tr>
                                                ))}
                                            </thead>
                                        </table>
                                    )}
                                    <div className="rpt_border">
                               
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        {this.state.nm_list.map((obj, index) => (
                                                            <th key={index}>{obj}</th>
                                                        ))}
                                                    </tr>
                                                    {this.state.recordColumn.length !== 0? this.state.recordColumn.map((obj, indext) => (
                                                     <>
                                                     {   obj.record ?  <tr key={indext}>
                                                         {obj.record.map((obj_in, index_in) => (
                                                            <td key={index_in}>{obj_in.value}</td>
                                                         ))}
                                                         </tr> : <tr key={indext}>
                                                        {obj.totalRecord.map((obj_in, index_in) => (
                                                           <td key={index_in}>{obj_in.value}</td>
                                                        ))}
                                                        </tr>}
                                                         
                                                 {/*          {   obj.totalRecord && <tr key={indext}>
                                                        {obj.totalRecord.map((obj_in, index_in) => (
                                                           <td key={index_in}>{obj_in.value}</td>
                                                        ))}
                                                        </tr>} */}
                                                     </>
                                                    )) : <div
                                                    className="text-center p-4"
                                                    style={{
                                                      backgroundColor: "#f8f9fa",
                                                      border: "1px solid #dee2e6",
                                                      borderRadius: "8px",
                                                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                      maxWidth: "80%"
                                                    }}
                                                  >
                                                    <p
                                                      className="h3 mb-3"
                                                      style={{
                                                        color: "#495057",
                                                        fontWeight: "bold"
                                                      }}
                                                    >
                                                      No Data Available
                                                    </p>
                                                    <p
                                                      className="h5 mb-3"
                                                      style={{
                                                        color: "#6c757d"
                                                      }}
                                                    >
                                                      There is no data to display for the selected report.
                                                    </p>
                                                    <p
                                                      style={{
                                                        color: "#6c757d",
                                                        fontSize: "1rem"
                                                      }}
                                                    >
                                                      Please try adjusting your selection criteria or check the data source.
                                                    </p>
                                                  </div>}
                                                </tbody>
                                            </table>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default WithNavigate(TabularReportCompo); 