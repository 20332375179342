import React, { Component } from "react";
import axios from "axios";
import GlobalContext from "./component/GlobalContext ";
import { AppConfig } from "./component/AppConfig";

export class MayaAjax extends Component {
    static contextType = GlobalContext;
  state = {
    scriptIncludeName:"",
    req:[],
    res:[],
    loca: AppConfig.loca,
  };

  rqq=[]
  
  constructor(scriptIncludeName) {
    super(scriptIncludeName);
    this.state.scriptIncludeName = scriptIncludeName;
  }

  
addParam =(key ,value)=>{
   // let rq=this.state.req;
    this.rqq.push({key:key,value:value})
 //  this.setState({req:[...rq]})
  
}

callAsync =(callBack)=>{
    let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
    var token = localStorage.getItem("token");
    console.log(obj );
    console.log(token);
    console.log(this.state.loca);
    
   if(token){
    axios
    .post(
      this.state.loca +
      "/loom/maya/ajax" ,
      obj,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
     console.log(res);
     let response=res.data;
     
      callBack(response)
    }
      
    )
    .catch((err)=>{
       console.log("error in maya ajax");
    })
   }
}

callApi= async ()=>{
  let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
  var token = localStorage.getItem("token");
  console.log(obj );
  console.log(token);
  console.log(this.state.loca);
  if(token){
    try{

      const res= await axios.post( this.state.loca +
        "/loom/maya/ajax" ,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        console.log(res.data);
        return res.data;
    }catch(err){
        return "";
    }
  }
}

 callSync = async () => {
  try {
    let resp = await this.callApi();
    console.log(resp);
    return resp;
  } catch (err) {
    console.log("Error in callSync", err);
    throw err; // Optional: rethrow if you want to handle the error further up the call stack
  }
};

apiRequest =(callBack)=>{
  let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
  var token = localStorage.getItem("token");
  console.log(obj );
  console.log(token);
  console.log(this.state.loca);
  
 if(token){
  axios
  .post(
    this.state.loca +
    "/loom/maya/ajax" ,
    obj,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{
   console.log(res);
   let response=res.data;
  //  let httpMethod=response.httpMethod;
  //  let endPoint=response.endPoint;
  //  let body=response.body

 
  console.log("response" ,response);
  
  //const url = `http://localhost:3002/?token=${token}`;
 //window.open(`http://localhost:3002/?token=${token}`, '_blank', 'noopener,noreferrer');

//  const targetUrl = 'http://localhost:3002';
//  const targetWindow = window.open(targetUrl, '_blank', 'noopener,noreferrer');

 // Wait for the target window to load, then send the token
//  targetWindow.addEventListener('load', () => {
//    targetWindow.postMessage({ token: token }, targetUrl);
//  });


    
   if(callBack)
    callBack(response)
  }
    
  )
  .catch((err)=>{
     console.log("error in maya ajax");
  })
 }

}

redirectOnUI=(callBack)=>{
 // callBack
   callBack();
}




  render() {
    return <div>ApUser</div>;
  }
}

export default MayaAjax;
