import React, { Component } from "react";
import axios from "axios";
import ModelList from "./model_list";
import { Modal, Button } from "react-bootstrap";
import "../css/materialcompo.css";
import mat from "../cssMod/materialcompo.module.css";
import "../css/formcompo.css";
import GlobalContext from "./GlobalContext ";
import { error } from "jquery";
import { message } from "antd";

class MaterialComponent extends Component {
  static contextType = GlobalContext;

  state = {
    loca: this.props.loca,
    isMobile: this.props.isMobile,
    userInfo: this.props.userInfo,
    laoding: false,
    page_error: false,
    error: "",
    page_message: false,
    message: "",
    show: false,
    setWorkshop: { id: "", value: "" },
    setInventory: { id: "", value: "" },
    setItem: { id: "", value: "" },
    setLoom: { id: "", value: "" },
    setOut: { id: "", value: "" },
    tabname: "",
    filtarray: [],
    fieldError: false,
    noItem: false,
    columnid: "",
    tabId: "",
    setWorker: { id: "", value: "" },
    loom_ar: [],
    isScrollUp: false,
    btn_disable: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.formChangeFn = this.formChangeFn.bind(this);
    this.setRef = this.setRef.bind(this);
    this.submitFn = this.submitFn.bind(this);
    this.checkBlankFieldVal = this.checkBlankFieldVal.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.scrollUpRef = React.createRef();
  }

  componentDidMount() {
    this.initialFunc();
  }

  initialFunc() {
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/get/loom/workshop/records", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (res) => {
          const rec = res.data;
          if (rec !== "") {
            if ("Error" in rec) {
              this.setState({
                page_error: true,
                error: "",
                page_message: false,
                message: "",
              });
            } else {
              if (this.state.userInfo.admin === "false") {
                if (rec.record[0].workshop) {
                  let xx = this.state.setWorkshop;
                  xx.value = rec.record[0].workshop.value;
                  xx.id = rec.record[0].workshop.id;
                  this.setState({ setWorkshop: xx });
                }
                if (rec.record.length > 1) {
                  this.setState({ loom_ar: rec.record[1].loom });
                }
              }
            }
          }
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: state.isMobile,
      };
    }
    return null;
  }

  componentDidUpdate(props) {
  }

  handleShow(tab) {
    let flt = this.state.filtarray;
    if (flt.length > 0) {
      flt = [];
    }
    if (tab === "loom") {
      let array_lm = this.state.loom_ar;
      let lom = "";
      if (this.state.setWorkshop.value !== "") {
        let ff = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: this.state.setWorkshop.id,
            value: this.state.setWorkshop.value,
          },
          dc: { id: "", value: "" },
        };
        flt.push(ff);
        if (array_lm.length > 0) {
          for (let i = 0; i < array_lm.length; i++) {
            if (i === 0) {
              lom = {
                co: "id",
                cl: "Id",
                mc: "=",
                an: array_lm[i].id,
                ct: "int",
                af: "AND",
                rf: { id: "0", value: "" },
                dc: { id: "", value: "" },
              };
              flt.push(lom);
            } else {
              lom = {
                co: "id",
                cl: "Id",
                mc: "=",
                an: array_lm[i].id,
                ct: "int",
                af: "OR",
                rf: { id: "0", value: "" },
                dc: { id: "", value: "" },
              };
              flt.push(lom);
            }
          }
        }
      } else {
        let ff = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: "0",
            value: "",
          },
          dc: { id: "", value: "" },
        };
        flt.push(ff);
      }
    } else if (tab === "inventory") {
      let xx = {
        co: "state",
        cl: "State",
        mc: "is",
        an: "new",
        ct: "choice",
        af: "AND",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [
          { name: "none", value: "None" },
          { id: "", name: "new", value: "New" },
          { id: "", name: "used", value: "Used" },
          { id: "", name: "old", value: "Old" },
        ],
      };
      if (
        this.state.setWorkshop.value !== "" &&
        this.state.setItem.value !== ""
      ) {
        let wrk = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: this.state.setWorkshop.id,
            value: this.state.setWorkshop.value,
          },
          dc: { id: "", value: "" },
          ch: [],
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: this.state.setItem.id, value: this.state.setItem.value },
          dc: { id: "", value: "" },
          ch: [],
        };
        flt.push(wrk);
        flt.push(itm);
        flt.push(xx);
      } else if (
        this.state.setWorkshop.value !== "" &&
        this.state.setItem.value === ""
      ) {
        let wrk = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: this.state.setWorkshop.id,
            value: this.state.setWorkshop.value,
          },
          dc: { id: "", value: "" },
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: "0", value: "" },
          dc: { id: "", value: "" },
        };
        flt.push(wrk);
        flt.push(itm);
        flt.push(xx);
      } else if (
        this.state.setWorkshop.value === "" &&
        this.state.setItem.value !== ""
      ) {
        let wrk = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: "0",
            value: "",
          },
          dc: { id: "", value: "" },
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: this.state.setItem.id, value: this.state.setItem.value },
          dc: { id: "", value: "" },
        };
        flt.push(wrk);
        flt.push(itm);
        flt.push(xx);
      } else {
        let wrk = {
          co: "workshop_id",
          cl: "Workshop",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: {
            id: "0",
            value: "",
          },
          dc: { id: "", value: "" },
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: "0", value: "" },
          dc: { id: "", value: "" },
        };
        flt.push(wrk);
        flt.push(itm);
        flt.push(xx);
      }
    } else if (tab === "material_in_use") {
      if (this.state.setLoom.value !== "" && this.state.setItem.value !== "") {
        let ff = {
          co: "loom_id",
          cl: "Loom",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: { id: this.state.setLoom.id, value: this.state.setLoom.value },
          dc: { id: "", value: "" },
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: this.state.setItem.id, value: this.state.setItem.value },
          dc: { id: "", value: "" },
        };
        let tt = {
          co: "state",
          cl: "State",
          mc: "=",
          an: "inuse",
          ct: "choice",
          af: "AND",
          rf: { id: "", value: "" },
          dc: { id: "", value: "" },
        };
        flt.push(ff);
        flt.push(itm);
        flt.push(tt);
      } else {
        let ff = {
          co: "loom_id",
          cl: "Loom",
          mc: "=",
          an: "",
          ct: "reference",
          af: "",
          rf: { id: "0", value: "" },
          dc: { id: "", value: "" },
        };
        let itm = {
          co: "item_id",
          cl: "Item",
          mc: "=",
          an: "",
          ct: "reference",
          af: "AND",
          rf: { id: "0", value: "" },
          dc: { id: "", value: "" },
        };
        let tt = {
          co: "state",
          cl: "State",
          mc: "=",
          an: "inuse",
          ct: "choice",
          af: "AND",
          rf: { id: "", value: "" },
          dc: { id: "", value: "" },
        };
        flt.push(ff);
        flt.push(itm);
        flt.push(tt);
      }
    }
    this.setState({ show: true, tabname: tab, filtarray: flt });
  }

  handleClose() {
    this.setState({ show: false });
  }

  formChangeFn(e, tab) {
    this.setState({ fieldError: false, page_message: false, message: "" });
    if (tab === "workshop") {
      let xx = this.state.setWorkshop;
      xx.value = e;
      xx.id = "";
      this.setState({ setWorkshop: xx });
    } else if (tab === "inventory") {
      let xx = this.state.setInventory;
      xx.value = e;
      xx.id = "";
      this.setState({ setInventory: xx });
    } else if (tab === "item") {
      let xx = this.state.setItem;
      xx.value = e;
      xx.id = "";
      this.setState({ setItem: xx });
    } else if (tab === "loom") {
      let xx = this.state.setLoom;
      xx.value = e;
      xx.id = "";
      this.setState({ setLoom: xx });
    } else if (tab === "material_in_use") {
      let xx = this.state.setOut;
      xx.value = e;
      xx.id = "";
      this.setState({ setOut: xx });
    }
  }

  setRef(val, ref_id) {
    this.setState({ page_message: false, message: "" });
    if (this.state.tabname === "workshop") {
      let xx = this.state.setWorkshop;
      xx.value = val;
      xx.id = ref_id;
      this.setState({ setWorkshop: xx, fieldError: false });
    } else if (this.state.tabname === "inventory") {
      let xx = this.state.setInventory;
      xx.value = val;
      xx.id = ref_id;
      this.setState({ setInventory: xx, fieldError: false });
    } else if (this.state.tabname === "loom") {
      let xx = this.state.setLoom;
      xx.value = val;
      xx.id = ref_id;
      this.setState({ setLoom: xx, fieldError: false });
    } else if (this.state.tabname === "item") {
      let xx = this.state.setItem;
      xx.value = val;
      xx.id = ref_id;
      this.setState({ setItem: xx, fieldError: false });
    } else if (this.state.tabname === "material_in_use") {
      let xx = this.state.setOut;
      xx.value = val;
      xx.id = ref_id;
      this.setState({ setOut: xx, fieldError: false });
    }
    this.handleClose();
  }

  // setRef(val, ref_id) {
  //   console.log(this.state.tabname, "tabname");
  //   const stateMap = {
  //     workshop: 'setWorkshop',
  //     inventory: 'setInventory',
  //     loom: 'setLoom',
  //     item: 'setItem',
  //     material_in_use: 'setOut'
  //   };

  //   const tabname = this.state.tabname;
  //   const stateVariable = stateMap[tabname];
  //   if (stateVariable) {
  //     let xx = this.state[stateVariable];
  //     xx.value = val;
  //     xx.id = ref_id;
  //     const newState = { [stateVariable]: xx, fieldError: false };
  //     this.setState(newState);
  //   }
  //   this.handleClose();
  // }

  submitFn() {
    this.setState({ loading: true, btn_disable: true });
    let body = {
      records: [
        {
          workshop: this.state.setWorkshop,
          inventory: this.state.setInventory,
          item: this.state.setItem,
          loom: this.state.setLoom,
          outItem: this.state.setOut,
        },
      ],
    };
    var token = localStorage.getItem("token");
    axios
      .post(this.state.loca + "/loom/set/material/records", body, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log("res" ,res);
          
          if (res !== "") {
            if ("Error" in res) {
              this.setState({
                loading: false,
                page_error: true,
                error: res.Error,
              });
            } else {
              this.setState({
                loading: false,
                page_message: true,
                message: res.Message,
              });
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
    this.setState({ isScrollUp: true }, () => {
      if (this.state.isScrollUp && this.scrollUpRef.current) {
        this.scrollUpRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  checkBlankFieldVal() {
    let wks = this.state.setWorkshop.value;
    let loom = this.state.setLoom.value;
    let item = this.state.setItem.value;
    let inv = this.state.setInventory.value;
    let out = this.state.setOut.value;
    let noItem = this.state.noItem;
    if (
      wks === "" ||
      loom === "" ||
      item === "" ||
      inv === "" ||
      (noItem === false && out === "")
    ) {
      this.setState({ fieldError: true });
    } else {
      if (wks !== "" && loom !== "" && item !== "" && inv !== "") {
        if (noItem === false && out !== "") {
          this.submitFn();
        } else if (noItem === true && out !== "") {
          out = "";
          this.submitFn();
        } else if (noItem === true && out === "") {
          this.submitFn();
        }
      }
    }
  }

  changeValue(val) {
    console.log(val);
    this.setState({ noItem: val, page_message: false, message: "", fieldError: false, });
  }

  refreshPage() {
    let wks = this.state.setWorkshop;
    wks.id = "";
    wks.value = "";
    this.setState({ setWorkshop: wks});

    let lom = this.state.setLoom;
    lom.id = "";
    lom.value = "";
    this.setState({ setLoom: lom });

    let itm = this.state.setItem;
    itm.id = "";
    itm.value = "";
    this.setState({ setItem: itm });

    let inv = this.state.setInventory;
    inv.id = "";
    inv.value = "";
    this.setState({ setInventory: inv });

    let outItm = this.state.setOut;
    outItm.id = "";
    outItm.value = "";
    this.setState({ setOut: outItm, btn_disable: false, page_error: false, error:"",page_message: false, message:"", noItem: false });

    this.initialFunc();
  }

  render() {
    // console.log(this.props.isMobile !== this.state.isMobile);
    // if (this.props.isMobile !== this.state.isMobile) {
    //   return {
    //     isMobile: this.props.isMobile,
    //   };
    // }
    return (
      <div className="container pagesetup mt-md-5" ref={this.scrollUpRef}>
        {window.innerWidth <= 768 ? (
          <div className={mat.maincompo2}>
            {/* <div className="tab_head mtr_tab text-center">
              {"Set Material Records"}
              </div> */}
            <div className="set-material-records-heading">
              <h1>Set Material Records</h1>
            </div>
            {/* mtr_bdr_mob */}
            <div className="pt-5 mx-4">
              {this.state.page_error === true && (
                <div className="alert alert-danger form_alt" role="alert">
                  {this.state.error}
                </div>
              )}
              {this.state.page_message === true && (
                <div className="alert alert-success form_alt" role="alert">
                  {this.state.message}
                </div>
              )}
              {this.state.fieldError === true && (
                // <div className="alert alert-danger form_alt" role="alert">
                //   {"Please fill mark in red"}
                // </div>
                <div className={mat.error_message}>
                  Please fill mark in red
                  {/* <span className={mat.close_btn}>&times;</span> */}
                </div>
              )}
              {/* <div className="row">
                <div className="col-md-6">
                  <div>
                    <span className="clt">Workshop</span>
                  </div>
                  <div>
                    <input
                      className={
                        this.state.fieldError === true &&
                          this.state.setWorkshop.value === ""
                          ? "mtr_client_search_mob form-control mtr_unverifi"
                          : "mtr_client_search_mob form-control"
                      }
                      type="text"
                      aria-label="Default"
                      value={this.state.setWorkshop.value}
                      onChange={(e) =>
                        this.formChangeFn(e.target.value, "workshop")
                      }
                    ></input>
                    <i
                      className="fa fa-search vlpointer mtr_clt_ref"
                      onClick={(e) => this.handleShow("workshop")}
                    ></i>
                  </div>
                </div>
                <div className="col-md-6">
                  <span className="clt">Loom</span>
                  <input
                    className={
                      this.state.fieldError === true &&
                        this.state.setLoom.value === ""
                        ? "mtr_client_search_mob form-control mtr_unverifi"
                        : "mtr_client_search_mob form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setLoom.value}
                    onChange={(e) => this.formChangeFn(e.target.value, "loom")}
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("loom")}
                  ></i>
                </div>
                <div className="col-md-6">
                  <div>
                    <span className="clt">Item</span>
                  </div>
                  <div>
                    <input
                      className={
                        this.state.fieldError === true &&
                          this.state.setItem.value === ""
                          ? "mtr_client_search_mob form-control mtr_unverifi"
                          : "mtr_client_search_mob form-control"
                      }
                      type="text"
                      aria-label="Default"
                      value={this.state.setItem.value}
                      onChange={(e) =>
                        this.formChangeFn(e.target.value, "item")
                      }
                    ></input>
                    <i
                      className="fa fa-search vlpointer mtr_clt_ref"
                      onClick={(e) => this.handleShow("item")}
                    ></i>
                  </div>
                </div>
                <div className="col-md-6">
                  <span className="clt">Inventory</span>
                  <input
                    className={
                      this.state.fieldError === true &&
                        this.state.setItem.value === ""
                        ? "mtr_client_search_mob form-control mtr_unverifi"
                        : "mtr_client_search_mob form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setInventory.value}
                    onChange={(e) =>
                      this.formChangeFn(e.target.value, "inventory")
                    }
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("inventory")}
                  ></i>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <span className="clt">No Out Item</span>
                  <input
                    className="mat_check"
                    type="checkbox"
                    onChange={(e) => this.changeValue(e.target.checked)}
                  ></input>
                </div>
                <div className="col-md-3"></div>
                {this.state.noItem === false && (
                  <div className="col-md-6">
                    <span className="clt">Out Item</span>
                    <input
                      className={
                        this.state.fieldError === true &&
                          this.state.setOut.value === ""
                          ? "mtr_client_search_mob form-control mtr_unverifi"
                          : "mtr_client_search_mob form-control"
                      }
                      type="text"
                      aria-label="Default"
                      value={this.state.setOut.value}
                      onChange={(e) =>
                        this.formChangeFn(e.target.value, "material_in_use")
                      }
                    ></input>
                    <i
                      className="fa fa-search vlpointer mtr_clt_ref"
                      onClick={(e) => this.handleShow("material_in_use")}
                    ></i>
                  </div>
                )}
              </div>
              <div className="btncen">
                <input
                  type="button"
                  value="Put To Use"
                  className="btn csm_btn csm_btn_pri col-md-2 sub-btn"
                  onClick={this.checkBlankFieldVal}
                ></input>
              </div>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                <div
                        className="btnsrc vlpointer "
                      >
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        ></i>
                      </div>
              </div> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <span className={mat.clt}>Workshop</span>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        className={`form-control ${
                          this.state.fieldError &&
                          this.state.setWorkshop.value === ""
                            ? "mtr_unverifi"
                            : ""
                        }`}
                        type="text"
                        aria-label="Workshop"
                        value={this.state.setWorkshop.value}
                        onChange={(e) =>
                          this.formChangeFn(e.target.value, "workshop")
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text vlpointer"
                          onClick={(e) => this.handleShow("workshop")}
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span className={mat.clt}>Loom</span>
                    <div className="input-group mb-3">
                      <input
                        className={`form-control ${
                          this.state.fieldError &&
                          this.state.setLoom.value === ""
                            ? "mtr_unverifi"
                            : ""
                        }`}
                        type="text"
                        aria-label="Loom"
                        value={this.state.setLoom.value}
                        onChange={(e) =>
                          this.formChangeFn(e.target.value, "loom")
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text vlpointer"
                          onClick={(e) => this.handleShow("loom")}
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <span className={mat.clt}>Item</span>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        className={`form-control ${
                          this.state.fieldError &&
                          this.state.setItem.value === ""
                            ? "mtr_unverifi"
                            : ""
                        }`}
                        type="text"
                        aria-label="Item"
                        value={this.state.setItem.value}
                        onChange={(e) =>
                          this.formChangeFn(e.target.value, "item")
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text vlpointer"
                          onClick={(e) => this.handleShow("item")}
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span className={mat.clt}>Inventory</span>
                    <div className="input-group mb-3">
                      <input
                        className={`form-control ${
                          this.state.fieldError &&
                          this.state.setInventory.value === ""
                            ? "mtr_unverifi"
                            : ""
                        }`}
                        type="text"
                        aria-label="Inventory"
                        value={this.state.setInventory.value}
                        onChange={(e) =>
                          this.formChangeFn(e.target.value, "inventory")
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text vlpointer"
                          onClick={(e) => this.handleShow("inventory")}
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <span className={mat.clt}>No Out Item</span>
                    <input
                      className="mat_check"
                      type="checkbox"
                      onChange={(e) => this.changeValue(e.target.checked)}
                    />
                  </div>
                  <div className="col-md-3"></div>
                  {!this.state.noItem && (
                    <div className="col-md-6">
                      <span className={mat.clt}>Out Item</span>
                      <div className="input-group mb-3">
                        <input
                          className={`form-control ${
                            this.state.fieldError &&
                            this.state.setOut.value === ""
                              ? "mtr_unverifi"
                              : ""
                          }`}
                          type="text"
                          aria-label="Out Item"
                          value={this.state.setOut.value}
                          onChange={(e) =>
                            this.formChangeFn(e.target.value, "material_in_use")
                          }
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text vlpointer"
                            onClick={(e) => this.handleShow("material_in_use")}
                          >
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="btncen">
                  <input
                    type="button"
                    value="Put To Use"
                    disabled={this.state.btn_disable}
                    className={
                      this.state.btn_disable
                        ? `btn csm_btn csm_btn_pri col-md-2 sub-btn disabled`
                        : `btn csm_btn csm_btn_pri col-md-2 sub-btn`
                    }
                    style={{
                      backgroundColor: this.state.btn_disable ? "gray" : "",
                    }}
                    // className="btn csm_btn csm_btn_pri col-md-2 sub-btn"
                    onClick={this.checkBlankFieldVal}
                  />
                </div> */}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={this.checkBlankFieldVal}
                  >
                    Put To Use
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" maincompo2">
            <div
              className="row mtr_tab"
              style={{
                marginLeft: "6rem",
                marginRight: "6rem",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <span>
                  <i
                    className="fa fa-refresh"
                    aria-hidden="true"
                    title="Refresh"
                    onClick={this.refreshPage}
                  ></i>
                </span>
              </div>
              <div className="col-md-7">
                <span className="tab_head text-center">
                  {"Set Material Records"}
                </span>
              </div>
            </div>
            <div className="mtr_bdr">
              {this.state.page_error === true && (
                <div className="alert alert-danger form_alt" role="alert">
                  {this.state.error}
                </div>
              )}
              {this.state.page_message === true && (
                <div className="alert alert-success form_alt" role="alert">
                  {this.state.message}
                </div>
              )}
              {this.state.fieldError === true && (
                <div className="alert alert-danger form_alt" role="alert">
                  {"Please fill mark in red"}
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <span className="clt">Workshop</span>
                  <input
                    className={
                      this.state.fieldError === true &&
                      this.state.setWorkshop.value === ""
                        ? "mtr_client_search form-control mtr_unverifi"
                        : "mtr_client_search form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setWorkshop.value}
                    onChange={(e) =>
                      this.formChangeFn(e.target.value, "workshop")
                    }
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("workshop")}
                  ></i>
                </div>
                <div className="col-md-6">
                  <span className="clt">Loom</span>
                  <input
                    style={{ marginLeft: "2.5rem" }}
                    className={
                      this.state.fieldError === true &&
                      this.state.setLoom.value === ""
                        ? "mtr_client_search form-control mtr_unverifi"
                        : "mtr_client_search form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setLoom.value}
                    onChange={(e) => this.formChangeFn(e.target.value, "loom")}
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("loom")}
                  ></i>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <span className="clt">Item</span>
                  <input
                    style={{ marginLeft: "3.2rem" }}
                    className={
                      this.state.fieldError === true &&
                      this.state.setItem.value === ""
                        ? "mtr_client_search form-control mtr_unverifi"
                        : "mtr_client_search form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setItem.value}
                    onChange={(e) => this.formChangeFn(e.target.value, "item")}
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("item")}
                  ></i>
                </div>
                <div className="col-md-6">
                  <span className="clt">Inventory</span>
                  <input
                    className={
                      this.state.fieldError === true &&
                      this.state.setItem.value === ""
                        ? "mtr_client_search form-control mtr_unverifi"
                        : "mtr_client_search form-control"
                    }
                    type="text"
                    aria-label="Default"
                    value={this.state.setInventory.value}
                    onChange={(e) =>
                      this.formChangeFn(e.target.value, "inventory")
                    }
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    onClick={(e) => this.handleShow("inventory")}
                  ></i>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <span className="clt">No Out Item</span>
                  <input
                    className="mat_check"
                    type="checkbox"
                    onChange={(e) => this.changeValue(e.target.checked)}
                  ></input>
                </div>
                <div className="col-md-3"></div>
                {this.state.noItem === false && (
                  <div className="col-md-6">
                    <span className="clt">Out Item</span>
                    <input
                      style={{ marginLeft: "1.5rem" }}
                      className={
                        this.state.fieldError === true &&
                        this.state.setOut.value === ""
                          ? "mtr_client_search form-control mtr_unverifi"
                          : "mtr_client_search form-control"
                      }
                      type="text"
                      aria-label="Default"
                      value={this.state.setOut.value}
                      onChange={(e) =>
                        this.formChangeFn(e.target.value, "material_in_use")
                      }
                    ></input>
                    <i
                      className="fa fa-search vlpointer mtr_clt_ref"
                      onClick={(e) => this.handleShow("material_in_use")}
                    ></i>
                  </div>
                )}
              </div>
              <div className="btncen">
                <input
                  type="button"
                  value="Put To Use"
                  disabled={this.state.btn_disable}
                  className={
                    this.state.btn_disable
                      ? `btn csm_btn csm_btn_pri col-md-2 sub-btn disabled`
                      : `btn csm_btn csm_btn_pri col-md-2 sub-btn`
                  }
                  style={{
                    backgroundColor: this.state.btn_disable ? "gray" : "",
                  }}
                  // className="btn csm_btn csm_btn_pri col-md-2 sub-btn"
                  onClick={this.checkBlankFieldVal}
                ></input>
              </div>
            </div>
          </div>
        )}
        <Modal
          /*      dialogClassName="my-modal" */
          size="xl"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht p-1">
            <ModelList
              setRef={(val, r_id) => this.setRef(val, r_id)}
              loca={this.state.loca}
              colBoolean={false}
              tabname={this.state.tabname}
              isMobile={this.state.isMobile}
              otherLocation={true}
              ref_filt={this.state.filtarray}
              columnid={this.state.columnid}
              tabId={this.state.tabId}
            ></ModelList>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default MaterialComponent;
