import React, { Component } from "react";
import "../css/listcompo.css";
import list from "../cssMod/listcompo.module.css";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import PreferenceComponent from "./preference_component";
import { Modal, Button } from "react-bootstrap";
import NewFilterCompo from "./NewFilterCompo";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
import WithNavigate from "./withNavigate";
import trueImg from "../images/check-mark.png";
import { isMobile } from "react-device-detect";
import GlobalContext from "./GlobalContext ";

class ListComponent extends Component {

  static contextType = GlobalContext;

  state = {
    name: "",
    list: [],
    page_clicked: 0,
    page_count: 0,
    page_records: "",
    record_count: 0,
    showlist: false,
    showbtn: false,
    col_mn: [],
    column_depend: [],
    // value: "",
    showupdownbtn: false,
    int_fields: [],
    date_fields: [],
    email_fields: [],
    boln_fields: [],
    str_fields: [],
    ref_fields: [],
    booleanfld: [],
    tm_list: [],
    filtarray: [],
    filt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    filString: "",
    sortColumn: { name: "", sort: true },
    listName: this.props.listName,
    rty: this.props.ty,
    filter: "",
    timeline: "",
    filter_unset: false,
    show: false,
    prefCall: [],
    button: [],
    mainrecord: {},
    tablabel: "",
    page_error: false,
    // error: "",
    columnid: "",
    cur_ref_name: "",
    cur_ref_type: "",
    cur_ref_index: 0,
    showmodel: false,
    noRecord: false,
    lst_record: [],
    loca: this.props.loca,
    srch: "",
    srch_column: "name",
    columnList: [],
    isMobile: this.props.isMobile,
    contextMenu: this.props.contextMenu,
    isDashboardinfo: this.props.isDashboardinfo,
    dashData: this.props.dashData,
    showContext: false,
    loading: true,
    // tableName: "",
    modal: false,
    btnNamee: "",
    url: "",
    isClick: false,
    isMenu: false,
    subMenu: false,
    activeMenu: false,
    listControlls: [],
    filterName: "",
    isMenuMob: false,
    openMenuItem: null,
    isTop: "49.3px",
    dashBoardRecordId:""
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.menuX = React.createRef();
    this.tablename = React.createRef();
    this.menuY = React.createRef();
    this.alreadyRun = React.createRef();
    this.filt1 = React.createRef();
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.filterItem = this.filterItem.bind(this);
    this.setList = this.setList.bind(this);
    this.setList1 = this.setList1.bind(this);
    this.filtersubmit = this.filtersubmit.bind(this);
    this.filterClear = this.filterClear.bind(this);
    this.showupdownbtn = this.showupdownbtn.bind(this);
    this.setSort = this.setSort.bind(this);
    this.callform = this.callform.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.searchColumn = this.searchColumn.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.callSearchbtn = this.callSearchbtn.bind(this);
    this.callfilter = this.callfilter.bind(this);
    this.calltimeline = this.calltimeline.bind(this);
    this.callbtn = this.callbtn.bind(this);
    this.handleCloseRefresh = this.handleCloseRefresh.bind(this);
    this.rightReadOnly = this.rightReadOnly.bind(this);
    this.leftReadOnly = this.leftReadOnly.bind(this);
    this.getChoiceRcd = this.getChoiceRcd.bind(this);
    this.setContext = this.setContext.bind(this);
    this.refresh = this.refresh.bind(this);
    this.menuFn = this.menuFn.bind(this);
    this.selectedRows = this.selectedRows.bind(this);
    this.selectedAllRows = this.selectedAllRows.bind(this);
    this.selectedAction = this.selectedAction.bind(this);
    this.setModal = this.setModal.bind(this);
    this.selectAction = this.selectAction.bind(this);
    // this.getScriptList = this.getScriptList.bind(this);
    // this.getScriptList = this.getScriptList.bind(this);
    this.getListRecord = this.getListRecord.bind(this);
    this.getSortAndFilter = this.getSortAndFilter.bind(this);
    this.storeSrot = this.storeSrot.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.externalRecord = this.externalRecord.bind(this);
    this.state.filtarray.push(this.state.filt);
    this.handleCloseFilterItem = this.handleCloseFilterItem.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    console.log("ismob", this.state.isMobile);
    this._isMounted = true;
    var element = document.getElementsByTagName("body").item(0);
    console.log(element);
    var element1 = document.getElementById("context_handler");
    element.addEventListener("mousedown", () => {
      if (this._isMounted) {
        this.setState({ showContext: false, isMenu: false, subMenu: false });
      }
    });
    element1.addEventListener("wheel", () => {
      if (this._isMounted) {
        this.setState({ showContext: false, isMenu: false, subMenu: false });
      }
    });


    console.log("this.state.isDashboardinfo ", this.state.isDashboardinfo);
    if (this.state.isDashboardinfo) {
      let dashBoardRecordId=this.props.dashBoardRecordId;
      this.setState({dashBoardRecordId:dashBoardRecordId})
      this.setList1(this.state.dashData);
  
    } else this.getSortAndFilter(); //getSortAndFilter


  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateSearchParams = (nextProps) => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");
    console.log("tableName:", tableName);
    let { filter } = location.state;
    console.log(filter);
    if (!filter) filter = [];

    this.setState(
      {
        listName: tableName,
        filter: filter,
      },
      () => {
        // Callback to log the updated state after setState
        console.log("Updated state:", this.state.listName);
      }
    );
  };
  componentDidUpdate(nextProps) {
 
     
    if (this.props.location.search !== nextProps.location.search) {
      this.updateSearchParams(nextProps);
    }

  console.log("cardprop" , this.props.dashData) ;
  console.log("cardstate" , this.state.dashData) ;
  console.log( "fmm",this.props.dashBoardRecordId ,this.state.dashBoardRecordId);
  
  if( this.props.dashBoardRecordId!=this.state.dashBoardRecordId ){
    console.log("didu2 ", this.state.isDashboardinfo);
    if (this.state.isDashboardinfo) {
      let dashBoardRecordId=this.props.dashBoardRecordId;
      this.setState({dashBoardRecordId:dashBoardRecordId ,dashboardData:this.props.dashboardData});
      this.setList1(this.props.dashData);
    }
  }

    if (
      /*   (nextProps.listName !== this.state.listName ||
        nextProps.filter !== this.state.filter) && */ this.props.location
        .search !== nextProps.location.search
    ) {
      console.log("this.state.isDashboardinfo ", this.state.isDashboardinfo);
      if (this.state.isDashboardinfo) {
        this.setList1(this.state.dashData);
      } else this.getSortAndFilter();
      //   let pg = localStorage.getItem("pageClicked");
      //   let p = 1;
      //   let srt = true;
      //   let srtPage = "id";
      //   var filterstate = "";
      //   if (pg) {
      //     let pag = JSON.parse(pg);
      //     p = pag.page;
      //     filterstate = pag.filter;
      //     let sort = pag.sort;
      //     srt = sort.sort;
      //     if (sort.name !== "") {
      //       srtPage = sort.name;
      //     }
      //     this.setState({ filtarray: filterstate, sortColumn: sort });
      //     localStorage.removeItem("pageClicked");
      //   }

      //   var lstnam = this.state.listName;
      //   // filterstate = this.state.filter;
      //   var tm = this.state.filter.timeline;
      //   var farray = [];
      //   if (filterstate === "") {
      //     if (
      //       this.state.filter === "" ||
      //       this.state.filter === "undefined" ||
      //       this.state.filter === undefined ||
      //       this.state.filter === "null" ||
      //       this.state.filter === null
      //     ) {
      //       farray.push(this.state.filt);
      //       filterstate = farray;
      //     } else {
      //       farray = this.state.filter.filter;
      //       filterstate = farray;
      //     }
      //   }
      //   this.setState({ listName: lstnam, filter: filterstate, timeline: tm });
      //   var tablere = '{"formRecordList":[';
      //   tablere += '{"application":{"id":"","value":"loom"}}';
      //   tablere +=
      //     ',{"table":{"id":"","value":"' +
      //     lstnam +
      //     '","label":"' +
      //     this.state.tablabel +
      //     '"}}';
      //   tablere += ',{"records":[]}';
      //   tablere +=
      //     ',{"page":{"record_count":"0","page_count":"1",' +
      //     '"page_clicked":"' +
      //     p +
      //     '","page_records":"0"}}';
      //   tablere += ',{"sort":{"asc":"' + srt + '","column":"' + srtPage + '"}}';
      //   tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
      //   tablere += ',{"timeLine":"' + tm + '"}]}';
      //   this.setList(tablere);
    }
    const innerText = this.myRef.current;
    if (innerText) {
      if (!this.state.modal) {
        innerText.value = "none";
      }
    }
  }

  /*   static getDerivedStateFromProps(props, state) {
      if (props.listName !== state.listName) {
        return {
          listName: props.listName,
          filter: props.filter,
          column_depend: [],
          isMobile: props.isMobile,
          tableName: props.listName,
        };
      } else if (props.listName === state.listName) {
        if (props.filter !== state.filter) {
          return {
            filter: props.filter,
            column_depend: [],
            isMobile: props.isMobile,
            tableName: props.listName,
          };
        }
      }
      return null;
    }
   */
  static getDerivedStateFromProps(props, state) {
    const { location } = props;

    console.log("Location:", location);

    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");

    let filter = location.state?.filter || [];
    console.log("abd376", filter);
    if (filter.length === 0) {
      filter = [];
      filter.push(state.filt);

      filter = { filter: filter, timeLine: "" };
      console.log(filter);
    }

    if (tableName !== state.listName) {
      console.log("ls1" ,tableName , state.listName);
      
      return {
        listName: tableName,
        filter: filter,
        column_depend: [],
        isMobile: props.isMobile,
        tableName: props.listName,
      };
    } else if (tableName === state.listName) {
      
      if (JSON.stringify(filter) !== JSON.stringify(state.filter)) {
        console.log("ls2" ,filter , state.filter);
        return {
          filter: filter,
          column_depend: [],
          isMobile: props.isMobile,
          tableName: props.listName,
        };
      }
    }
    if (state.contextMenu === props.contextMenu) {
      console.log("equal");
      return {
        contextMenu: props.contextMenu,
      };
    } else if (state.contextMenu !== props.contextMenu) {
      console.log("not equal");
      return {
        contextMenu: props.contextMenu,
      };
    }
  //  console.log("dr" , props.dashBoardRecordId ,state.dashBoardRecordId);
    
    // if(props.dashBoardRecordId!=state.dashBoardRecordId){
    //   console.log("coming in dashBoardRecordId");
    //   return {
    //     dashBoardRecordId: props.dashBoardRecordId,
    //   };
    // }
    return null;
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleCloseRefresh() {
    this.setState({ show: false });
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    tablere +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    tablere += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(tablere);
    // this.getSortAndFilter();
  }

  handleCloseFilterItem() {
    this.setState({ showlist: false });
  }

  leftReadOnly() {
    if (this.state.page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  }

  rightReadOnly() {
    if (this.state.page_clicked === this.state.page_count) {
      return true;
    } else {
      return false;
    }
  }

  previousPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked - 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  nextPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked + 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    console.log(pp);
    this.setList(pp);
  }

  firstPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  lastPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"0","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.record_count % 50 === 0
        ? this.state.page_count - 1
        : this.state.page_count) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  externalRecord() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  showupdownbtn(hd) {
    console.log("hd", hd);
    var sarray = this.state.sortColumn;
    console.log(sarray);
    let flt = JSON.parse(JSON.stringify(this.state.filtarray));
    // if (!this.alreadyRun.current) {
    //   flt.pop();
    //   let ff = {
    //     co: "",
    //     cl: "",
    //     mc: "",
    //     an: "",
    //     ct: "",
    //     af: "",
    //     rf: { id: "", value: "" },
    //     dc: { id: "", value: "" },
    //   };
    //   flt.push(ff);
    // }
    //{"sort":{"asc":"true","column":"","init":"false"}}
    var clmn = "";
    var srt = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (this.state.list[l].heading[ll].name === hd) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < this.state.list.length; li++) {
        for (var lll = 0; lll < this.state.list[li].heading.length; lll++) {
          if (this.state.list[li].heading[lll].name === hd) {
            clmn = this.state.list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    }
    // this.setState({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.storeSrot(srt, pp);
  }

  setSort() {
    var clmn = "";
    var pp = "";
    var srt = this.state.sortColumn.name;
    console.log(this.state.sortColumn);
    console.log(this.state.sortColumn.name);
    if (srt !== "") {
      console.log("fewaoh");
      console.log(this.state.list);
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          console.log(
            this.state.list[l].heading[ll].name,
            this.state.sortColumn.name,
            this.state.list[l].heading[ll].name === this.state.sortColumn.name,
            "sorttt"
          );
          if (
            this.state.list[l].heading[ll].name === this.state.sortColumn.name
          ) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }
      if (this.state.sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        this.state.list[0].heading[1].name +
        '","init":"false"}}';
    }
    return pp;
  }

  setList(tablere) {
    console.log(tablere);
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(this.state.loca + "/loom/get/multiplerecord", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(listrecord);
          this.setList1(listrecord);
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
  }

  setList1(listrecord) {
    console.log(this.state.isDashboardinfo);
    console.log("abdullll", listrecord);
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    var col_list = [];
    console.log("abd734", listrecord);
    if (listrecord !== "") {
      if ("Error" in listrecord) {
        this.setState({
          loading: false,
          page_error: true,
          error: listrecord.Error,
        });
      } else {
        var scnd = listrecord.formRecordList[2];
        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            this.setState({ list: [] });
          }
        } else {
          var page_clicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var page_count1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );

          var flt = listrecord.formRecordList[5];
          var fltarr = listrecord.formRecordList[5]?.filter;
          var tmln = listrecord.formRecordList[6]?.timeLine;

          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (fltarr[f].ct === "reference") {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          if (listrecord.formRecordList[2].records.length === 0) {
            this.setState({ noRecord: true });
          } else {
            this.setState({ noRecord: false });
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              // if (i === 0) {
              //   if (j === 0) {
              //   } else {
              //     hd.push({
              //       label:
              //         listrecord.formRecordList[2].records[i].record[j]
              //           .label,
              //       name: listrecord.formRecordList[2].records[i].record[j]
              //         .name,
              //     });
              //   }
              // }
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
              } else if (j === 1) {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value
                        .value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                } else {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                }
              } else {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value
                        .value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                } else {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                }
              }
            }
            console.log(this.state.isDashboardinfo);
            if (this.state.isDashboardinfo === true) {
              console.log("feijf");
              va_ll.push({ colr: in_vl });
            } else {
              in_vl.splice(0, 0, { ref: false });
              va_ll.push({ colr: in_vl });
            }
          }
          console.log(in_vl);
          console.log(va_ll);

          for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
            if (listrecord.formRecordList[9].column[c].type === "String") {
              col_list.push({
                label: listrecord.formRecordList[9].column[c].label,
                name: listrecord.formRecordList[9].column[c].name,
                type: listrecord.formRecordList[9].column[c].type,
              });
            }
          }
          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
            });
          }
          if (this.state.isDashboardinfo === true) {
            columnarry.push({ heading: hd, rcd: va_ll });
          } else {
            hd.splice(0, 0, { ref: false });
            columnarry.push({ heading: hd, rcd: va_ll });
          }
          var cla = JSON.parse(
            JSON.stringify(listrecord.formRecordList[9].column)
          );
          cla.unshift({ label: "None", name: "none" });
          this.setState({
            tablabel: listrecord.formRecordList[1].table.label,
            loading: false,
            list: columnarry,
            page_clicked: page_clicked1,
            page_count: page_count1,
            page_records: page_records1,
            record_count: record_count1,
            col_mn: cla,
            prefCall: listrecord.formRecordList[9].column,
            filter: flt,
            filtarray: fltarr,
            timeline: tmln,
            filString: filterString,
            button: listrecord.formRecordList[11].button,
            columnList: col_list,
          });
        }
      }
    }
  }

  callform(e, nam, tab, r_id) {
    console.log("mouse: ", e.button, nam, tab, r_id);
    const { navigateWithSearchParams } = this.props;
    if (nam === "first") {
      let pg = {
        page: this.state.page_clicked,
        filter: this.state.filtarray,
        sort: this.state.sortColumn,
      };
      localStorage.setItem("pageClicked", JSON.stringify(pg));
      //  this.props.showFormCompo(tab, r_id, "record");
      console.log("record is howing");
      //  this.props.navigate("/private/form")

      if (e.button === 0) {
        navigateWithSearchParams(
          "/private/form",
          {
            rty: "record",
            tableName: tab,
            rid: r_id,
          },
          {}
        );
      } else if (e.button === 1) {
        // Middle mouse button
        // window.open("/private/form",
        //   {
        //     rty: "record",
        //     tableName: tab,
        //     rid: r_id,
        //   }
        // );
    
      }
    } else if (nam === "second") {
    } else if (nam === "new") {
      this.props.showFormCompo(tab, 0, "new");

      navigateWithSearchParams(
        "/private/form",
        {
          rty: "new",
          tableName: tab,
          rid: 0,
        },
        {tabTitle:tab}
      );
    }
  }

  selectedRows(checked, index) {
    console.log(this.state.list[0].rcd[index].colr[0].ref);
    this.state.list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      this.state.list[0].heading[0].ref = false;
    }
    this.setState({ list: this.state.list });
  }

  setModal(mod) {
    this.setState({ modal: mod });
  }

  selectedAllRows(checked) {
    let rcd = this.state.list[0].rcd;
    if (checked) {
      this.state.list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        this.state.list[0].rcd[i].colr[0].ref = true;
      }
      this.setState({ list: this.state.list });
    } else {
      this.state.list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        this.state.list[0].rcd[i].colr[0].ref = false;
      }
      this.setState({ list: this.state.list });
    }
  }

  selectedAction(st) {
    let arr = st.split(",");
    let btnNamee = arr[0];
    let url = arr[1];

    if (this.state.list[0].rcd.length > 0) {
      if (url === "/loom/delete/record") {
        this.setState({ btnNamee: "Delete", modal: true, url: url });
      } else {
        let btnNamee = arr[0];
        this.setState({ btnNamee: btnNamee, modal: true, url: url });
        // this.selectAction(url ,btnNamee);
      }
    }
  }

  selectAction(url) {
    this.setState({ isClick: true });
    console.log("seleeect");
    let rcd = this.state.list[0].rcd;
    let postBody = [];
    console.log(rcd);
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        {
          console.log(rcd[i].colr[1].r_id);
        }
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    console.log(postBody);
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    console.log(jso);
    if (postBody.length > 0) {
      var token = localStorage.getItem("token");
      var userDetails = localStorage.getItem("userDetails");
      axios
        .post(this.state.loca + "/loom/selectedAction", jso, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            console.log(record);
            if ("Error" in record) {
              toast(record.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              if ("message" in record) {
                toast(record.message, {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else {
                toast(record, {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              }
              // this.refresh();
              this.externalRecord();
            }
          },
          (error) => {
            this.setState({ loading: false, modal: false, isClick: false });
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          this.setState({ loading: false, modal: false, isClick: false });
        });
    } else {
      toast("Select any record", {
        position: "top-center",
        theme: "colored",
        type: "error",
      });
      this.setState({ loading: false, modal: false, isClick: false });
    }
  }

  dismiss() {
    this.props.unmountMe();
  }

  filterItem() {
    console.log(this.state.showlist, this.state.showbtn);

    this.setState({ showlist: !this.state.showlist });
    this.setState({ showbtn: !this.state.showbtn });
    // if (this.state.showlist === true) {
    //   this.setState({ isTop: "49.3px" })
    // } else {
    //   this.setState({ isTop: "" });
    // }
  }

  filterClear() {
    var fltarray = [];
    fltarray.push(this.state.filt);
    this.setState({ filtarray: fltarray, noRecord: false });
    var tm = "";
    this.setState({ timeline: tm });
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + tm + '"}]}';
    this.setList(pp);
    if (this.state.showlist && this.state.isMobile) {
      console.log("line 1139 render hui");
      this.setState({ showlist: false });
    }
  }

  filtersubmit() {
    let srt = this.state.sortColumn;
    srt.sort = true;
    this.setState({sortColumn: srt});
    this.alreadyRun.current = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    // console.log(this.state.filtarray);
    fs += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    fs += ',{"timeLine":"' + this.state.timeline + '"}]}';
    console.log(fs);
    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      console.log("1st cond");
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        console.log("hjjhff");
        this.setList(fs);
      }
    } else {
      console.log("2nd cond");
      this.setList(fs);
    }
    if (this.state.showlist && this.state.isMobile) {
      console.log("line 1139 render hui");
      this.setState({ showlist: false });
    }
  }

  callbtn(e, nam) {
    var btn = this.state.button;
    var mnrecord = this.state.mainrecord;
    var btntype = "";
    var calltype = "";
    var foundbtn = false;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].name === nam) {
        btntype = btn[ij].returnWebLocation;
        calltype = btn[ij].call_type;
        foundbtn = true;
        break;
      }
    }
    if (foundbtn === true && btntype !== "" && calltype !== "") {
      this.setState({
        page_error: false,
        error: "",
        loading: true,
        page_message: false,
        message: "",
      });
      if (calltype === "module") {
        this.callform(e, "new", this.state.listName, 0);
      } else {
        mnrecord.formRecord[2].record = this.state.record;
        var token = localStorage.getItem("token");
        axios
          .post(this.state.loca + btn[ij].webUrl, mnrecord, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const rcd = resp.data;
            if (rcd !== "") {
              if ("Error" in rcd) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: rcd.Error,
                });
              } else {
                this.setState({ loading: false });
                var msg = rcd.formRecord[4].message;
                if (msg !== "") {
                  this.setState({ page_message: true, message: msg });
                }
                if (btntype === "NextPage") {
                  // var nextP = btn[ij].nextPage;
                  var nextP = rcd.formRecord[3].button.nextPage;
                  if (nextP === "List") {
                    this.callNextPage();
                  }
                }
              }
            }
          });
      }

      // } else {
      // 	this.setState({
      // 		page_error: true,
      // 		error: error_String,
      // 	});
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // 	// this.props.unmountMe();
      // }
    }
  }

  async callfilter(filtarray, in_index, col_id) {
    var rcd = this.state.list;
    if (col_id !== -1) {
      for (let i = 0; i < rcd.length; i++) {
        if (
          filtarray[in_index].ct === "choice" &&
          filtarray[in_index].ch.length === 0
        ) {
          let chc = await this.getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              filtarray[in_index].ch = res;
              filtarray[in_index].an = res[0].name;
            } else {
              filtarray[in_index].ch = [];
            }
          });
        }
      }
    }
    this.setState({
      filtarray: filtarray,
    });
  }

  async getChoiceRcd(col_id) {
    var token = localStorage.getItem("token");
    let ck = await axios
      .get(this.state.loca + "/loom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if ("Error" in res) {
            this.setState({
              loading: false,
              page_error: true,
              error: res.Error,
            });
          } else {
            let chk = res.choiceRecords;
            chk.unshift({ name: "none", value: "None" });
            return chk;
          }
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
    return ck;
  }

  calltimeline(timeline) {
    this.setState({
      timeline: timeline,
    });
  }

  changeSearch(val) {
    if (val === "") {
      this.setState({ srch: "" });
      this.filterClear();
    } else {
      this.setState({ srch: val });
    }
  }

  callSearchbtn() {
    var fld = this.state.srch_column;
    var val = this.state.srch;
    var col = this.state.columnList;
    var type = "";
    var label = "";
    for (var c = 0; c < col.length; c++) {
      if (fld === col[c].name) {
        type = col[c].type;
        label = col[c].label;
      }
    }
    if (val.length > 2) {
      let filter = [
        {
          ct: type,
          af: "",
          mc: "contains",
          cl: label,
          co: fld,
          an: val,
          rf: { id: "", value: "" },
        },
      ];
      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","value":"loom"}}';
      fs +=
        ',{"table":{"id":"","value":"' +
        this.state.listName +
        '","label":"' +
        this.state.tablabel +
        '"}}';
      fs += ',{"records":[]}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
      fs += ',{"filter":' + JSON.stringify(filter) + "}";
      fs += ',{"timeLine":""}]}';
      this.setList(fs);
    }
  }

  searchColumn(e) {
    this.setState({ srch_column: e.target.value });
  }

  setContext(val, x, y) {
    if (val === 2) {
      this.menuX.current = x;
      this.menuY.current = y;
      this.setState({ showContext: true });
    } else {
      this.setState({ showContext: false });
    }
  }

  getListRecord(listName, srrt, flt) {
    this.setState({ listName: listName });
    let pg = localStorage.getItem("pageClicked");
    console.log("pppppppppggggg: ", pg);
    this.state.sortColumn.name = srrt.column;
    this.state.sortColumn.sort = srrt.asc === "true" ? true : false;
    let p = 1;
    let srt = srrt.asc;
    let srtPage = srrt.column;
    var filterstate = "";
    var filter = this.state.filter;
    var tm = "";
    console.log(this.state.filter);
    if (filter !== "null" && filter !== null) {
      this.filt1.current = this.state.filter.filter;
      tm = this.state.filter.timeline;
      console.log(tm);
    } else {
      this.filt1.current = flt.filter;
      tm = flt.timeline;
    }
    if (pg) {
      let pag = JSON.parse(pg);
      p = pag.page;
      filterstate = pag.filter;
      let sort = pag.sort;
      srt = sort.sort;
      if (sort.name !== "") {
        srtPage = sort.name;
      }
      this.setState({ filtarray: filterstate, sortColumn: sort });
      localStorage.removeItem("pageClicked");
    }

    var farray = [];
    if (filterstate === "") {
      if (
        this.filt1.current === "" ||
        this.filt1.current === "undefined" ||
        this.filt1.current === undefined ||
        this.filt1.current === "null" ||
        this.filt1.current === null ||
        !this.filt1.current
      ) {
        farray.push(this.state.filt);
        this.state.sortColumn.name = "";
        this.state.sortColumn.sort = true;
        filterstate = farray;
      } else {
        farray = this.filt1.current;
        filterstate = farray;
      }
    }

    this.setState({
      filter: filterstate,
      timeline: tm,
    });
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    tablere +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      p +
      '","page_records":"0"}}';
    tablere +=
      ',{"sort":{"asc":"' +
      srt +
      '","column":"' +
      srtPage +
      '","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + tm + '"}]}'; //flt.timeLine
    console.log("filtttttttttttttttttt:", filterstate);
    this.setList(tablere);
  }

  getSortAndFilter() {
    console.log("kulssssssssssssssssssssssss: ");
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/get/sortfilter/" + this.state.listName, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          console.log("sort: ", rsp);
          this.getListRecord(
            this.state.listName,
            rsp.value[0].sort,
            rsp.value[1]
          );
        },
        (error) => {
          console.log(error);
        }
      );

    console.log("calling listcontrolls");
    axios
      .get(this.state.loca + "/loom/get/listcontrols/" + this.state.listName, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          console.log(resp);
          let rsp = resp.data;
          console.log("listcontrolls 1: ", rsp);
          for (let k = 0; k < rsp.listControlls.length; k++) {
            console.log("adding");
            if (rsp.listControlls[k].controllName === "Filters") {
              // rsp.listControlls.unshift({filter:{filter:[],timeline:''}, filterId:`0`,filterName:'None'})
              var fltarray = [];
              fltarray.push(this.state.filt);
              rsp.listControlls[k].subMenu.unshift({
                filter: { filter: fltarray, timeline: "" },
                filterId: `0`,
                filterName: "None",
              });
            }
          }
          console.log("listcontrolls 2: ", rsp);
          this.setState({ listControlls: rsp.listControlls });
          /* this.getListRecord(
            this.state.listName,
            rsp.value[0].sort,
            rsp.value[1]
          ); */
        },
        (error) => {
          console.log(error);
        }
      );
  }

  storeSrot(sort, pp) {
    var token = localStorage.getItem("token");
    let js = { tableName: this.state.listName, sort: sort };
    axios
      .post(this.state.loca + "/loom/set/sort", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          this.setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveFilter(filterId = "0") {
    console.log(filterId);
    var token = localStorage.getItem("token");
    let ft = { filter: this.state.filtarray, timeLine: this.state.timeline };
    let js = {
      filterName: this.state.filterName,
      filterId: filterId,
      tableName: this.state.listName,
      filter: ft,
    };
    console.log(this.state.filtarray);
    console.log(this.state.timeline);
    if (
      this.state.timeline === "" &&
      this.state.filtarray[0].co === "" &&
      filterId === "0" /* ||
      (!this.state.filtarray[0].an && !this.state.filtarray[0].rf.id) */
    ) {
      toast.error("Select filter");
      return;
    } else {
      axios
        .post(this.state.loca + "/loom/save/filter", js, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(
          (rsp) => {
            let resp = rsp.data;
            console.log(resp);
            if (filterId === `0`) toast.success("Saved filter");
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  removeFilter() {
    var token = localStorage.getItem("token");
    let js = { tableName: this.state.listName };
    axios
      .post(this.state.loca + "/loom/remove/filter", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          console.log("remove:", resp);
          this.getSortAndFilter();
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  refresh() {
    console.log("refer");
    this.getSortAndFilter();
  }

  back() {
    // navigate(-1);
  }

  menuFn(body) {
    // this.refresh();
    // this.back();
    // if (body !== "refresh()") {
    //   this.props.showViewCompo(this.state.listName);
    // }
    const { navigateWithSearchParams } = this.props;

    console.log(this.state.listName);
    if (body === "back()") {
      this.back();
    } else if (body === "refresh()") {
      this.refresh();
    } else {
      navigateWithSearchParams(
        "/private/setup-view",
        {
          tableName: this.state.listName,
        },
        {}
      );
    }
  }

  selectFilterFromSubMenu = (fil, filterId) => {
    if (filterId === `0`) {
      this.filterClear();
    }
    console.log("filter selected", fil, filterId);
    let filtarray = fil.filter;
    let timeline = fil.timeline;

    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    // console.log(this.state.filtarray);
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    console.log(fs);
    let len = filtarray.length;

    console.log("2nd cond");
    this.setList(fs);
    console.log(filterId);
    var token = localStorage.getItem("token");
    let ft = { filter: filtarray, timeLine: timeline };
    let js = {
      filterName: this.state.filterName,
      filterId: filterId,
      tableName: this.state.listName,
      filter: ft,
    };

    axios
      .post(this.state.loca + "/loom/submenu/filter", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );
    let listcon = this.state.listControlls;
    for (let k = 0; k < listcon.length; k++) {
      console.log("adding");
      if (listcon[k].controllName === "Filters") {
        // rsp.listControlls.unshift({filter:{filter:[],timeline:''}, filterId:`0`,filterName:'None'})
        //  var fltarray = [];
        //fltarray.push(this.state.filt);
        //rsp.listControlls[k].subMenu.unshift({filter:{filter:fltarray,timeline:''}, filterId:`0`,filterName:'None'})
        let subMenu = listcon[k].subMenu;
        console.log(subMenu);
        for (let j = 0; j < subMenu.length; j++) {
          let ott = subMenu[j];
          if (ott.filterId == filterId) {
            ott.selected = true;
          } else {
            ott.selected = false;
          }
        }
      }
    }

    console.log(listcon);
    this.setState({ listControlls: listcon });

    if (this.state.isMobile) {
      this.setState({ activeMenu: null });
      this.setState({ isMenuMob: false });
    }
  };

  clickOnMenu = (e) => {
    console.log("click on menu");
    const { isMenu, isMenuMob } = this.state;
    console.log("abd1706", isMenu, isMenuMob);
    this.setState({ isMenu: true });
    if (isMobile) {
      e.stopPropagation();
      if (isMenuMob === false) {
        this.setState({ isMenuMob: true });
      } else {
        this.setState({ isMenuMob: false });
      }
    }
  };

  subMenu = () => {
    console.log("sub menu");
    this.setState({ subMenu: true });
  };
  handleMouseEnter = (menu) => {
    console.log("tocu");
    this.setState({ activeMenu: menu });
  };

  handleMouseLeave = () => {
    this.setState({ activeMenu: null });
    if (this.state.isMobile) {
      this.setState({ isMenuMob: false });
    }
  };

  handleClick = (controllName) => {
    if (this.state.isMobile) {
      this.setState({
        activeMenu:
          this.state.activeMenu === controllName ? null : controllName,
      });
    }
  };

  handleTouchStart = (controllName) => {
    this.setState((prevState) => ({
      openMenuItem:
        prevState.openMenuItem === controllName ? null : controllName,
    }));
  };

  handleTouchEnd = () => {
    // Remove this if you want the menu to stay open after touch
    // this.setState({ openMenuItem: null });
  };

  handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevents the click from bubbling up
  };

  render() {
    return (
      <div
        id="context_handler"
        className={`${list['pagesetup']} reference-dropdown-hide-on-wheel`}
        style={{
          overflowX: "auto",
          overflowY: isMobile || this.state.showlist ? "hidden" : "",
        }}
        onClick={() => this.setState({ isMenuMob: false })}
      >
        {this.state.loading === true ? (
          <div className="g-compo-loader">
            {console.log("abdload", this.state.loading)}
            <WorkInProgress
              tableName={this.state.listName}
              type={this.state.rty}
            ></WorkInProgress>
          </div>
        ) : (
          <div>
            {this.state.page_error === true && (
              <div className="alert alert-danger form_alt" role="alert">
                {this.state.error}
              </div>
            )}
            {isMobile ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{this.state.tablabel}</span>
                </div>
                <div className="col-lg filter-ic text-center">
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={this.state.srch_column}
                      onChange={(e) => this.searchColumn(e)}
                    >
                      {this.state.columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <input
                      className="srch-mob form-control"
                      type="search"
                      aria-label="Search"
                      value={this.state.srch}
                      aria-hidden="true"
                      onChange={(e) => this.changeSearch(e.target.value)}
                    ></input>
                  )}
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      aria-hidden="true"
                      onClick={() => this.callSearchbtn()}
                    ></input>
                  )}
                </div>

                {!this.state.isDashboardinfo && (
                  <div
                    className="col-lg martop10 filter-ic d-flex "
                    style={{ justifyContent: "center" }}
                  >
                    <div style={{ fontSize: "20px" }}>
                      <i
                        className="fa-solid fa-bars  vlpointer "
                        /* aria-hidden="true" */
                        onClick={this.clickOnMenu}
                        style={{ padding: "0px 4px" }}
                      ></i>
                      {!this.state.isDashboardinfo && this.state.isMenuMob && (
                        <ul
                          className="dropdown-menu"
                          style={{
                            display: "block",
                          }}
                          onClick={this.handleDropdownClick}
                        >
                          {console.log("handleDropdownclick")}
                          {!this.state.isDashboardinfo &&
                            this.state.isMenuMob && (
                              <div>
                                {this.state.listControlls.map((obj, index) => (
                                  <li
                                    key={index}
                                    onTouchStart={() =>
                                      this.handleTouchStart(obj.controllName)
                                    }
                                    onTouchEnd={this.handleTouchEnd}
                                  >
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>{obj.controllName}</span>
                                      <span>{">"}</span>
                                    </a>
                                    {obj.subMenu.length !== 0 &&
                                      this.state.openMenuItem ===
                                      obj.controllName && (
                                        <ul
                                          className="submenu"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: "0px",
                                          }}
                                        >
                                          {obj.subMenu.map((subobj, ind) => (
                                            <li
                                              className="submenu-item"
                                              key={ind}
                                              onTouchEnd={this.handleTouchEnd}
                                              onTouchStart={(e) => {
                                                this.selectFilterFromSubMenu(
                                                  subobj.filter,
                                                  subobj.filterId
                                                );
                                              }}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px",
                                              }}
                                            >
                                              <span>{subobj.filterName}</span>
                                              {subobj.selected && (
                                                <img
                                                  src={trueImg}
                                                  height={15}
                                                  width={15}
                                                />
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                  </li>
                                ))}
                              </div>
                            )}
                        </ul>
                      )}
                  

                      <i
                        className="fa fa-filter vlpointer pdtop5 mx-1"
                        aria-hidden="true"
                        onClick={this.filterItem}
                      ></i>
                      <i
                        className="fa fa-cog vlpointer cog_pd pdtop5"
                        aria-hidden="true"
                        onClick={this.handleShow}
                      ></i>
                    </div>
                    <div className="text-end centre-flex">
                      <span
                      //  style={{ marginRight: "0.5em" }}
                      >
                        <input
                          className=" mybt"
                          type="button"
                          value="<<"
                          onClick={this.firstPage}
                          disabled={this.leftReadOnly()}
                        />
                        <input
                          className=" mybt"
                          type="button"
                          value="<"
                          onClick={this.previousPage}
                          disabled={this.leftReadOnly()}
                        />
                      </span>
                      <span className="form-control in-put">
                        {this.state.page_records}
                      </span>
                      {/* <span>
                      <input
                        className="in-put"
                        type="text"
                        value={this.state.page_records}
                        readOnly={true}
                      ></input>
                    </span> */}
                      <span className="text-uppercase spfont"> of </span>
                      <span className="rcd_count spfont">
                        {this.state.record_count}
                      </span>
                      <span>
                        <input
                          className=" mybt"
                          type="button"
                          value=">"
                          onClick={this.nextPage}
                          disabled={this.rightReadOnly()}
                        />
                      </span>
                      <span>
                        <input
                          className=" mybt"
                          type="button"
                          value=">>"
                          disabled={this.rightReadOnly()}
                          onClick={this.lastPage}
                        />
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-lg mt-0 mt-md-2 text-end me-2">
                  <select
                    ref={this.myRef}
                    defaultValue="None"
                    style={{ width: 120, borderRadius: "0.25rem" }}
                    onChange={(e) => {
                      e.target.value !== "none" &&
                        this.selectedAction(e.target.value);
                    }}
                  >
                    <option value="none">None</option>
                    {!this.state.isDashboardinfo &&
                      this.state.button.length > 0 &&
                      this.state.button.map(
                        (obj, oo_i) =>
                          obj.ui_type === "selectedAction" && (
                            <option
                              key={oo_i}
                              value={`${obj.value},${obj.webUrl}`}
                            >
                              {obj.value}
                            </option>
                          )
                      )}
                  </select>
                  {!this.state.isDashboardinfo &&
                    this.state.button.map(
                      (obj, oo_i) =>
                        obj.ui_type === "formButton" && (
                          <input
                            type="button"
                            className="csm_btn csm_btn_pri col-md-2 sub-btn"
                            key={oo_i}
                            // onClick={(e) => this.callbtn(obj.name)}
                            onMouseDown={(e) => this.callbtn(e, obj.name)}
                            value={obj.value}
                          ></input>
                        )
                    )}
                </div>

                <Modal
                  show={this.state.showlist && this.state.isMobile}
                  onHide={this.handleCloseFilterItem}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="mod-ht">
                    <div>
                      {!this.state.isDashboardinfo && (
                        <div>
                          <div className="mb-2 row g-2">
                            <div className="col-12 col-md-5">
                              <input
                                type="text"
                                placeholder="Filter Name"
                                className="form-control form-control-sm"
                                value={this.state.filterName}
                                onChange={(e) => {
                                  this.setState({ filterName: e.target.value });
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-7 d-flex justify-content-between mt-2 mt-md-0">
                              <Button
                                variant="primary"
                                size="sm"
                                className="w-50 me-2"
                                disabled={this.state.filterName === ""}
                                onClick={() => this.saveFilter()}
                              >
                                Save Filter
                              </Button>
                              <Button
                                variant="primary"
                                size="sm"
                                className="w-50"
                                onClick={this.removeFilter}
                              >
                                Remove Filter
                              </Button>
                            </div>
                          </div>
                          <NewFilterCompo
                            showlist={this.state.showlist}
                            col_mn={this.state.col_mn}
                            col_depend={this.state.column_depend}
                            call_fil={(filtarray, in_index, col_id) =>
                              this.callfilter(filtarray, in_index, col_id)
                            }
                            filtarray={this.state.filtarray}
                            timeline={this.state.timeline}
                            call_tm={(timeline) => this.calltimeline(timeline)}
                            loca={this.state.loca}
                            isMobile={this.state.isMobile}
                            isReference={true}
                          ></NewFilterCompo>
                        </div>
                      )}
                      <div className="mt-4 d-flex justify-content-end">
                        {console.log(
                          "abd2045",
                          this.state.filtarray,
                          this.state.filtarray[0].an
                        )}
                        <Button
                          variant="primary"
                          className="mx-2"
                          disabled={
                            this.state.filtarray[0].an === "" &&
                            this.state.filtarray[0].rf.value === ""
                          }
                          onClick={this.filtersubmit}
                        >
                          Run
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-2"
                          onClick={this.filterClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              <>
                <div
                  className={`row ${list.bck}`}
                  /*  style={{ position: "fixed", width: "74.1%", zIndex: "2" }} */
                  onContextMenu={(e) => {
                    e.preventDefault();
                    this.setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                >
                  {console.log("dashborad", !this.state.isDashboardinfo)}
                  {!this.state.isDashboardinfo && (
                    <div
                      className=""
                    // onWheel={}
                    >
                      {!this.state.isDashboardinfo &&
                        this.state.showContext && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              top: this.menuY.current,
                              left: this.menuX.current,
                            }}
                          >
                            {!this.state.isDashboardinfo &&
                              this.state.contextMenu && 
                              this.state.contextMenu.map((obj, ind) => (
                                <div>
                                  {obj.name === "refresh" && (
                                  <li
                                  key={ind}
                                  onMouseDown={() => {
                                    this.menuFn(obj.script);
                                  }}
                                >
                                  <a className="dropdown-item" href="#">
                                    {obj.Label}
                                  </a>
                                </li>
                                  )}
                                </div>
                              ))}
                          </ul>
                        )}
                    </div>
                  )}
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {!this.state.isDashboardinfo && (
                      <div>
                        <i /* className="fa-solid fa-bars vlpointer" */
                          className="fa-solid fa-bars  vlpointer "
                          /* aria-hidden="true" */
                          onClick={this.clickOnMenu}
                          style={{ padding: "0px 4px" }}
                        ></i>
                        {!this.state.isDashboardinfo && this.state.isMenu && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              /*    top: this.menuY.current,
                            left: this.menuX.current, */
                            }}
                          >
                            {!this.state.isDashboardinfo &&
                              this.state.isMenu && (
                                <div>
                                  {this.state.listControlls.map(
                                    (obj, index) => (
                                      <li
                                        key={index}
                                        onMouseEnter={() =>
                                          this.handleMouseEnter(
                                            obj.controllName
                                          )
                                        }
                                        onMouseLeave={this.handleMouseLeave}
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span>{obj.controllName}</span>
                                          <span>{">"}</span>
                                        </a>
                                        {obj.subMenu.length !== 0 &&
                                          this.state.activeMenu ===
                                          obj.controllName && (
                                            <ul
                                              className="submenu"
                                              style={{
                                                listStyleType: "none",
                                                paddingLeft: "0px",
                                              }}
                                            >
                                              {obj.subMenu.map(
                                                (subobj, ind) => (
                                                  <li
                                                    className="submenu-item"
                                                    key={ind}
                                                    onMouseDown={(e) => {
                                                      this.selectFilterFromSubMenu(
                                                        subobj.filter,
                                                        subobj.filterId
                                                      );
                                                    }}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "4px",
                                                    }}
                                                  >
                                                    <span>
                                                      {subobj.filterName}
                                                    </span>
                                                    {/* {subobj.selected && (<span>true</span>)} */}
                                                    {subobj.selected && (
                                                      <img
                                                        src={trueImg}
                                                        height={15}
                                                        width={15}
                                                      />
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                      </li>
                                    )
                                  )}

                                  {/*  <li

                                  onMouseEnter={() => this.handleMouseEnter('Show')}
                                  onMouseLeave={this.handleMouseLeave}
                                >
                                  <a className="dropdown-item" href="#" style={{display:"flex",justifyContent:"space-between"}}>
                                    <span>{"Show"}</span>
                                    <span>{">"}</span>
                                  </a>
                                  {this.state.activeMenu === 'Show' && (
                                    <ul className="submenu">
                                      <li className="submenu-item">10 page</li>
                                      <li className="submenu-item">20 page</li>
                                      <li className="submenu-item">30 page</li>

                                    </ul>
                                  )}

                                </li> */}
                                </div>
                              )}
                          </ul>
                        )}
                        <i
                          className="fa fa-filter vlpointer"
                          aria-hidden="true"
                          onClick={this.filterItem}
                        ></i>

                        <i
                          className="fa fa-cog vlpointer px-1"
                          aria-hidden="true"
                          onClick={this.handleShow}
                        ></i>

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === true && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Run"
                              aria-hidden="true"
                              onClick={this.filtersubmit}
                            ></input>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === true && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Clear"
                              aria-hidden="true"
                              onClick={this.filterClear}
                            ></input>
                          )}
                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <select
                              className={`form-select ${list.namelist}`}
                              aria-label="Default"
                              value={this.state.srch_column}
                              onChange={(e) => this.searchColumn(e)}
                            >
                              {this.state.columnList.map((obj2, index) => (
                                <option key={index} value={obj2.name}>
                                  {obj2.label}
                                </option>
                              ))}
                            </select>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <input
                              className="srch form-control"
                              type="search"
                              aria-label="Search"
                              value={this.state.srch}
                              aria-hidden="true"
                              onChange={(e) =>
                                this.changeSearch(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.callSearchbtn();
                                }
                              }}
                            ></input>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Search"
                              aria-hidden="true"
                              onClick={() => this.callSearchbtn()}
                            ></input>
                          )}
                      </div>
                    )}
                    <div
                      className={
                        this.state.isDashboardinfo === true
                          ? "col-md-12 l-box-mar-pad"
                          : ""
                      }
                    >
                      <span
                        className={
                          this.state.isDashboardinfo === true
                            ? "fw-bold text-center list-font-sz"
                            : "obj_head"
                        }
                      >
                        {this.state.tablabel}
                      </span>
                    </div>

                    {!this.state.isDashboardinfo && (
                      <div>
                        <div className="tpi">
                          <select
                            ref={this.myRef}
                            defaultValue="None"
                            style={{ width: 120, borderRadius: "0.25rem" }}
                            onChange={(e) => {
                              e.target.value !== "none" &&
                                this.selectedAction(e.target.value);
                            }}
                          >
                            <option value="none">None</option>
                            {!this.state.isDashboardinfo &&
                              this.state.button.length > 0 &&
                              this.state.button.map(
                                (obj, oo_i) =>
                                  obj.ui_type === "selectedAction" && (
                                    <option
                                      key={oo_i}
                                      value={`${obj.value},${obj.webUrl}`}
                                    >
                                      {obj.value}
                                    </option>
                                  )
                              )}
                          </select>
                          {!this.state.isDashboardinfo &&
                            this.state.button.map(
                              (obj, oo_i) =>
                                obj.ui_type === "formButton" && (
                                  <input
                                    type="button"
                                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                                    key={oo_i}
                                    // onClick={(e) => this.callbtn(obj.name)}
                                    onMouseDown={(e) =>
                                      this.callbtn(e, obj.name)
                                    }
                                    value={obj.value}
                                  ></input>
                                )
                            )}

                          <span style={{ marginLeft: "0.5em" }}>
                            <input
                              className=" mybt"
                              type="button"
                              value="<<"
                              onClick={this.firstPage}
                              disabled={this.leftReadOnly()}
                            />
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value="<"
                              onClick={this.previousPage}
                              disabled={this.leftReadOnly()}
                            />
                          </span>
                          <span className="form-control in-put">
                            {this.state.page_records}
                          </span>
                          <span className="text-uppercase spfont"> of </span>
                          <span className="rcd_count spfont">
                            {this.state.record_count}
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value=">"
                              onClick={this.nextPage}
                              disabled={this.rightReadOnly()}
                            />
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value=">>"
                              disabled={this.rightReadOnly()}
                              onClick={this.lastPage}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {!this.state.isDashboardinfo &&
              this.state.showlist === false &&
              this.state.filString !== "" && (
                <div style={{ marginLeft: "0.5rem" }}>
                  {this.state.filString}
                </div>
              )}
            {/* <div> */}
            {!this.state.isDashboardinfo &&
              !this.state.isMobile &&
              this.state.showlist === true && (
                <div>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      placeholder="Filter Name"
                      className="m-0 py-1 ms-2 mt-2 border-2"
                      value={this.state.filterName}
                      onChange={(e) => {
                        this.setState({ filterName: e.target.value });
                      }}
                    />
                    <input
                      className=" btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                      type="button"
                      value="Save Filter"
                      // disabled={rightReadOnly()}
                      disabled={this.state.filterName === "" ? true : false}
                      onClick={() => {
                        this.saveFilter();
                      }}
                    />

                    <input
                      className="btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                      type="button"
                      value="Remove Filter"
                      // disabled={rightReadOnly()}
                      onClick={this.removeFilter}
                    />
                  </div>
                  <NewFilterCompo
                    showlist={this.state.showlist}
                    col_mn={this.state.col_mn}
                    col_depend={this.state.column_depend}
                    call_fil={(filtarray, in_index, col_id) =>
                      this.callfilter(filtarray, in_index, col_id)
                    }
                    filtarray={this.state.filtarray}
                    timeline={this.state.timeline}
                    call_tm={(timeline) => this.calltimeline(timeline)}
                    loca={this.state.loca}
                    isMobile={this.state.isMobile}
                    isReference={true}
                  ></NewFilterCompo>
                </div>
              )}
            {console.log(this.context.formHeight, this.context.formHeight - 10.6)}
            {this.state.list.length === 0 && <div>No Record Found</div>}
            {this.state.list.map((lstobj, lst_i) => (
              <div
                className="heading_top table_set overflow-auto"
                key={lst_i}
                style={{
                  height: isMobile
                    ?
                    this.context.formHeight - 91
                    :
                    this.state.showlist
                      ?
                      this.context.formHeight - 144.6
                      :
                      this.context.formHeight - 10.6,
                  position: "relative",
                }}
              >
                {/* over */}
                <table className="table table-bordered table-striped table-hover p-1">
                  <thead
                    // className={
                    //   this.props.isOpen === true
                    //     ? "sticky-header"
                    //     : "sticky-position"
                    // }
                    style={{
                      position: "sticky",
                      top: "0"
                    }}
                  >
                    <tr className="obj_name">
                      {lstobj.heading.map((obj, obj_i) =>
                        !this.state.isDashboardinfo ? (
                          <th key={obj_i} className="vlpointer">
                            {obj_i === 0 ? (
                              <span>
                                <input
                                  type="checkbox"
                                  checked={obj.ref}
                                  onChange={(e) => {
                                    this.selectedAllRows(e.target.checked);
                                  }}
                                ></input>
                              </span>
                            ) : (
                              // !this.state.isDashboardinfo ?
                              <div
                                onClick={(e) => this.showupdownbtn(obj.name)}
                              >
                                {obj.label}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === true && (
                                    <i className=" icpadding fa fa-arrow-up"></i>
                                  )}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === false && (
                                    <i className=" icpadding fa fa-arrow-down"></i>
                                  )}
                              </div>
                            )}
                          </th>
                        ) : (
                          <th key={obj_i} className="vlpointer">
                            {/* {console.log(obj_i)} */}
                            <div>
                              {obj.label}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === true && (
                                  <i className=" icpadding fa fa-arrow-up"></i>
                                )}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === false && (
                                  <i className=" icpadding fa fa-arrow-down"></i>
                                )}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody
                    className="tbody bd_z_index_prop"
                  >
                    {lstobj.rcd.map((objj, objj_i) => (
                      <tr className="obj_value" key={objj_i}>
                        {objj.colr.map((objr, objr_i) =>
                          !this.state.isDashboardinfo ? (
                            <td
                              key={objr_i}
                              className={
                                objr.firstrecord === true
                                  ? "val_pad val_under vlpointer"
                                  : "val_pad"
                              }
                              onMouseDown={
                                objr.firstrecord === true
                                  ? (e) =>
                                    this.callform(
                                      e,
                                      "first",
                                      objr.table,
                                      objr.r_id
                                    )
                                  : (e) => this.callform(e, "second", "", "")
                              }
                            >
                              {/* && !this.state.isDashboardinfo */}
                              {objr_i === 0 ? (
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={objr.ref}
                                    onChange={(e) => {
                                      this.selectedRows(
                                        e.target.checked,
                                        objj_i
                                      );
                                    }}
                                  ></input>
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {objr.type === "filter" ||
                                    objr.type === "filter_ref" ||
                                    objr.name === "json"
                                    ? JSON.stringify(objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value)
                                    : objr.type === "group_key_value"
                                      ? JSON.stringify(objr.value.properties?.length>50?objr.value.properties.slice(0,50)+" ...":objr.value.properties)
                                      :objr.type==="html"?  <span dangerouslySetInnerHTML={{__html:objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value  }}>
              
                                      </span> :objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value   }
                                </span>
                              )}
                            </td>
                          ) : (
                            <td key={objr_i}>
                         <span>
                                  {" "}
                                  {objr.type === "filter" ||
                                    objr.type === "filter_ref" ||
                                    objr.name === "json"
                                    ? JSON.stringify(objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value)
                                    : objr.type === "group_key_value"
                                      ? JSON.stringify(objr.value.properties?.length>50?objr.value.properties.slice(0,50)+" ...":objr.value.properties)
                                      :objr.type==="html"?  <span dangerouslySetInnerHTML={{__html:objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value  }}>
              
                                      </span> :objr.value?.length > 50? objr.value.slice(0,50)+" ...":objr.value   }
                                </span>
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            {/* </div> */}
            {this.state.noRecord === true && (
              <div className="norcd">No Record Found</div>
            )}
            {console.log(this.state.url)}
            <Modal
              show={this.state.modal}
              onHide={() => this.setModal(!this.state.modal)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm {this.state.btnNamee}</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setModal(!this.state.modal)}
                  disabled={this.state.isClick}
                  style={{ backgroundColor: this.state.isClick ? "gray" : "" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => this.selectAction(this.state.url)}
                  disabled={this.state.isClick}
                  style={{ backgroundColor: this.state.isClick ? "gray" : "" }}
                >
                  {this.state.btnNamee}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        <Modal
          dialogClassName="m-md-auto my-modal d-flex justify-content-center align-items-center "
          // dialogClassName="my-modal"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PreferenceComponent
              pref="user"
              setRef={(val) => this.setRef(val)}
              columnarray={this.state.prefCall}
              tablename={this.state.listName}
              handleClose={() => this.handleCloseRefresh()}
              loca={this.state.loca}
              isMobile={this.state.isMobile}
            ></PreferenceComponent>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default WithNavigate(ListComponent);
