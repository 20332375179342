
export const AppConfig={
  //  loca:"http://192.168.0.106:8082"
        loca: "https://apis.loomyarn.com",
    // loca: "https://loomyarn.com:8082",
  //  loca: "http://192.168.0.103:8082", /*Abdulhaleem*/
//loca:"http://localhost:8082"

};


