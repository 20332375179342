import React, { Component } from "react";
import axios from "axios";
import { Select, Card, Button, Flex, Input, Space } from "antd";
import { SearchProps } from 'antd/es/input/Search';
import WorkInProgress from "./work_in_progress";
import "../css/userInfo.css";
import { Modal } from "react-bootstrap";
import ModelList from "./model_list";
import ListComponent from "./list_component";
import FormComponent from "./form_component";
const { Option } = Select;
const { Search } = Input;

export class UserInfo extends Component {
  state = {
    loca: this.props.loca,
    loading: false,
    info_Rec: [],
    selectedOption: null,
    fieldError: false,
    show: false,
    filtarray: [],
    rid: "",
    tabname: "",
    fdnameVisible: false,
    dbName: "",
    inputVisible: false,
    setAddress: { id: "", value: "" },
    setWorker: { id: "", value: "" },
    setWorkshop: { id: "", value: "" },
    refRecord: { id: "", value: "" },
    showCard: false,
    singleRecord: [],
    multipleRecord: [],
    list: [],
    noRecord: false,
    page_error: false,
    page_clicked: 0,
    page_count: 0,
    tablabel: "",
    col_mn: [],
    prefCall: [],
    colType: "",
    refValue:"",
    refId:""
  };

  constructor(props) {
    super(props);

    // this.showDetails = this.showDetails.bind(this);
    this.formChangeFn = this.formChangeFn.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/info/name", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const rcd = resp.data;
          console.log("record", rcd);
          if (rcd !== "") {
            if ("Error" in rcd) {
              this.setState({
                loading: false,
                page_error: true,
                error: rcd.Error,
              });
            } else {
              this.setState({
                info_Rec: rcd.infoRecord[2].records,
              });
              console.log("info", this.state.info_Rec);
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
          console.log(error);
        }
      );
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = (tab) => {
    let flt = this.state.filtarray;
    if (flt.length > 0) {
      flt = [];
    }
    let ff = {
      co: "workshop_id",
      cl: "Workshop",
      mc: "=",
      an: "",
      ct: "reference",
      af: "",
      rf: {
        id: "0",
        value: "",
      },
      dc: { id: "", value: "" },
    };
    flt.push(ff);
    this.setState({ show: true, filtarray: flt });
  }

  formChangeFn(e, tab) {
    console.log(this.state.tabname, "tabname" ,e , tab);
    this.setState({ fieldError: false });

  }

  setRef(val, ref_id, tab, dbName) {
    console.log(this.state.tabname, "tabname");

    console.log("refRecrd" ,val ,ref_id);
    
   let obj={id:ref_id ,value:val}
   this.setState({     refValue:val ,setOut: obj, fieldError: false });

   this.showDetails(
    obj.id,
    obj.value,
    this.state.tabname,
    this.state.dbName,
   
  )

    this.handleClose();
  }



  callInfoDashboardRecord = (value) => {
    // console.log(nam);
    const { info_Rec, loca } = this.state;

    console.log("this is a dashRecord",value);
    // this.setState({ loading: true });
    const selectedOption = info_Rec.find(item => item.name === value);
    console.log(selectedOption);
    if (selectedOption) {
      this.setState({
        rid: selectedOption.id,
        selectedOption: value,
        dbName: selectedOption.name,
        fdname: selectedOption.fdname, //select
        tabname: selectedOption.tabName,
        fdnameVisible: true,
        inputVisible: true,
        loading: false,
      });
    }
    // console.log(this.state.dName);
    console.log("Selected value:", value);
  };


  showDetails = (rid, value, tabname, dbName) => {
    console.log(rid, value, tabname, dbName);
    console.log("Show Details is Clicked");
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/multi/table/info/map/" +
        tabname +
        "/" +
        dbName +
        "/" +
        rid +
        "/" +
        value,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const userRecord = res.data;
        console.log(userRecord);
        // console.log(sin, mul);
        this.setState({
          colType: userRecord.infoRecord[0].colType,
          singleRecord: userRecord.infoRecord[0].SingleRecord,
          multipleRecord: userRecord.infoRecord[0].MultipleRecord,
       
           refId:rid,
          showCard: true
        });
        console.log(this.state.colType);
      },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
    // this.toggleCardVisibility();
    
  }





  render() {
    const { rid, info_Rec, loading, selectedOption, showCard, fieldError, fdname, show, loca, dbName, tabname, fdnameVisible, isMobile } = this.state; // Destructure infoRecord from state
    return (
      <div className="UserInfo">
        <div className="pagesetup">
          {fieldError === true && (
            <div className="alert alert-danger form_alt" role="alert">
              {"Please fill mark in red"}
            </div>
          )}
          {loading === true ? (
            <WorkInProgress></WorkInProgress>
          ) : (
            <Select
              value={selectedOption}
              onChange={(value) => this.callInfoDashboardRecord(value)}
              style={{
                width: "300px",
                marginTop: "10px",
                marginLeft: "13px",
              }}
              showSearch
              placeholder="Select Dashboard Info"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {info_Rec.map((item, i) => (
                <Option key={i} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
          {selectedOption && fdnameVisible && (
            <div className="row" style={{ marginTop: "15px", marginLeft: "0px" }}>

              
                <div className="col-md-6">
           
                  <p className="mb-0 ms-1 font-monospace">{this.state.dbName}</p>
                  <input
                    className={
                      fieldError && this.state.refRecord.value === ""
                        ? "mtr_client_search form-control mtr_unverifi"
                        : "mtr_client_search form-control"
                    }
                    style={{ margin: "0px" }}
                    type="text"
                    aria-label="Default"
                    value={this.state.refValue}
                /*     onChange={(e) => { console.log(e); this.formChangeFn(e.target.value, this.state.tabname, this.state.dbName) }} */
                  ></input>
                  <i
                    className="fa fa-search vlpointer mtr_clt_ref"
                    style={{ marginLeft: "-1px" }}
                    onClick={() => this.handleShow()}
                  ></i>
                  {console.log(this.state.setWorkshop.value)}
                  {/* {this.state.setWorkshop.value && (() =>)} */}
                </div>
              
            </div>
          )}
          {showCard === true ? (
            <>
              <div className={`${this.state.colType === "col6" ? "row" : ""}`}>
                {/* <div> */}
                {console.log(this.state.multipleRecord)}
                {this.state.multipleRecord.map((card, index) => (
                  // <div key={index} className=" col-md-6 col-lg-4 col-xl-3">
                  <div
                    key={index}
                    className={`${this.state.colType === "col6" ? "card-style-col-6  p-4" : "card-style-col-12 px-3 py-3"}`}
                  >

                    {/* col-md-6  */}
                    <div className="card crd">
                      <div className="card-body">
                        <ListComponent
                          unmountMe={""}
                          listName={""}
                          timeline={""}
                          /*    filter={this.state.filter}
                             showFormCompo={(nm, rid, ty) =>
                               this.showFormCompo(nm, rid, ty)
                             } */
                          /*  ty={this.state.formRecordType} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          contextMenu={this.state.contextMenu}
                          labelContext={this.state.labelContext}
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          showViewCompo={this.showViewCompo}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                          isDashboardinfo={true}
                          dashData={card}
                          dashBoardRecordId={this.state.refId}
                        ></ListComponent>

                      </div>
                    </div>
                  </div>
                ))}
                {/* </div> */}
                {/* <div className="row"> */}
                {console.log(this.state.multipleRecord)}
                {console.log(this.state.singleRecord)}
                {this.state.singleRecord.map((card, index) => (
                  // <div key={index} className=" col-md-6 col-lg-4 col-xl-3">
                  <div
                    key={index}
                    className={`${this.state.colType === "col6" ? "card-style-col-6  p-4" : "card-style-col-12 px-3 py-3"}`}
                  >
                    {/* col-md-6 */}
                    <div className="card crd">
                      <div className="card-body">
                        <FormComponent
                          // showListCompo={(nm, fil, tm) =>
                          //   this.showListCompo(nm, fil, tm)
                          // }
                          unmountMe={this.handleFormCompo}
                          tabname={this.state.tabname}
                          rid={this.state.rid}
                          ty={this.state.formRecordType}
                          isMobile={this.state.isMobile}
               
                          loca={this.state.loca}
                          contextMenu={this.state.contextMenu}
                          labelContext={this.state.labelContext}
                          showViewCompo={this.showViewCompo}
                          showStatusCompo={this.showStatusCompo}
                          isDashboardInfo={true}
                          dashBoardRecordId={this.state.refId}
                          dashboardData={card}
                         
                        // showErrorCompo={(error) => this.showErrorCompo(error)}
                        // showNotificationCompo={(cnt) =>
                        //   this.showNotificationCompo(cnt)
                        // }

                        // showIncomeExpense={this.showIncomeExpense}
                        ></FormComponent>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}

        </div>
        <Modal
          size="xl"
          show={show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht p-1">
            <ModelList
              setRef={(val, r_id) => this.setRef(val, r_id, tabname, dbName)}
              loca={loca}
              colBoolean={true}
              tabname={tabname}
              isMobile={isMobile}
              otherLocation={true}
            // ref_filt={this.state.filtarray}
            ></ModelList>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}

export default UserInfo;
