import React, { Component, Fragment } from "react";
import axios from "axios";
import "../css/checklist.css";
import chk from "../cssMod/checklist.module.css"
import "../css/formcompo.css";
import WorkInProgress from "./work_in_progress";
import WorkInProgressSmall from "./WorkInProgressSmall";
import { Modal, Button, Tab, Nav, Col, Row } from "react-bootstrap";
import "../css/listcompo.css";
import "../css/formcompo.css";
import "../css/relationlist.css";
import { Alert, Snackbar } from '@mui/material';


class CheckList_CompoNew extends Component {
    state = {
        chk_record: [],
        event_record: [],
        event_record1: [],
        imp_record: [],
        // imp_record1: [],
        evt_choice: [],
        ier_button: [],
        // att_record: [],
        loading: false,
        stateLoading: false,
        evtLoading: false,
        chekLoading: false,
        page_error: false,
        error: "",
        page_message: false,
        message: "",
        columnId: 0,
        objName: "",
        objLabel: "",
        objIndex: "",
        objIndex_in: "",
        objType: "",
        showModelList: false,
        refrecord: {},
        loca: this.props.loca,
        allCard: ["Check List", "Event", "Important Record"],
        changedEventRecord: [],
        openSnackBar: false,
        snacKbarMsg: "",
        severity: ""
    };

    /*   {"token":"","validation":"","error":"","authenticated":false,"passwordReset":false,"newPasswordSet":true,"usernameChanged":false,"username":"worker1","name":"","appCode":""}
     */
    constructor(props) {
        super(props);
        this.setRecord = this.setRecord.bind(this);
        this.formChangefn = this.formChangefn.bind(this);
        this.validationfn = this.validationfn.bind(this);
        this.clickRefrence = this.clickRefrence.bind(this);
        this.setRefrecord = this.setRefrecord.bind(this);
        this.callbtnImp = this.callbtnImp.bind(this);
        this.callEventbtn = this.callEventbtn.bind(this);
        this.callCheckListbtn = this.callCheckListbtn.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.fieldverify = this.fieldverify.bind(this);
        this.setOpen = this.setOpen.bind(this);
    }

    componentDidMount() {
        var token = localStorage.getItem("token");
        this.setState({ loading: true });
        // axios
        //     .get(this.state.loca + "/loom/today/checklist", {
        //         headers: {
        //             authorization: "Bearer " + token,
        //         },
        //     })
        //     .then(
        //         (resp) => {
        //             var chkrecord = resp.data;
        //             console.log(chkrecord);
        //             this.setRecord(chkrecord);
        //         },
        //         (error) => {
        //             this.props.showErrorCompo();
        //             console.log(error);
        //         }
        //     );
        this.getTodaysChecklist();
    }

    getTodaysChecklist = () => {
        var token = localStorage.getItem("token");
        this.setState({ loading: true });
        axios
            .get(this.state.loca + "/loom/get/todays/checklist", {
                headers: {
                    authorization: "Bearer " + token,
                },
            })
            .then(
                (resp) => {
                    var chkrecord = resp.data;
                    console.log(chkrecord);
                    // this.setRecord(chkrecord);
                    this.setState({
                        loading: false,
                        chk_record: chkrecord.checkListRecords[0].records,
                        chk_button: chkrecord.checkListRecords[1].button,
                    })
                    this.getActiveEvent();
                },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                }
            );
    }


    handleCloseSnackBar = () => {
        this.setState({ openSnackBar: false, severity: "", snacKbarMsg: "" })
    }

    handleOpenSnackBar = (severity, snacKbarMsg) => {
        this.setState({ openSnackBar: true, severity: severity, snacKbarMsg: snacKbarMsg })
    }
    getActiveEvent = () => {
        var token = localStorage.getItem("token");

        axios
            .get(this.state.loca + "/loom/get/active/event", {
                headers: {
                    authorization: "Bearer " + token,
                },
            })
            .then(
                (resp) => {
                    var chkrecord = resp.data;
                    console.log(chkrecord);
                    // this.setRecord(chkrecord);
                    this.setState({


                        event_record: chkrecord.eventRecords[0].records,
                        evt_choice: chkrecord.eventRecords[1].choice,
                        evt_button: chkrecord.eventRecords[2].button,

                        // imp_record1: chkrecord.checkList[2].importantRecords[0],
                    });
                    this.getImportantEventRecord()
                },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                }
            );
    }

    getImportantEventRecord = () => {
        var token = localStorage.getItem("token");

        axios
            .get(this.state.loca + "/loom/getImportantEventRecord", {
                headers: {
                    authorization: "Bearer " + token,
                },
            })
            .then(
                (resp) => {
                    var chkrecord = resp.data;
                    console.log(chkrecord);
                    var lst = [];
                    for (
                        var i = 0;
                        i < chkrecord.importantRecords[0].imp_records.length;
                        i++
                    ) {
                        chkrecord.importantRecords[0].imp_records[i].position =
                            i;
                        chkrecord.importantRecords[0].imp_records[i].ref = true;
                        for (
                            var j = 0;
                            j <
                            chkrecord.importantRecords[0].imp_records[i].records
                                .length;
                            j++
                        ) {
                            chkrecord.importantRecords[0].imp_records[i].records[
                                j
                            ].rec_chg = false;
                        }
                    }

                    var imp_record = [];
                    var ier_button = [];
                    if (
                        chkrecord.importantRecords.length > 0 &&
                        JSON.stringify(chkrecord.importantRecords[0]) !== "{}"
                    ) {
                        imp_record = chkrecord.importantRecords[0].imp_records;
                        ier_button = chkrecord.importantRecords[1].button;
                    }
                    this.setState({

                        imp_record: imp_record,
                        ier_button: ier_button,

                    });

                },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                }
            );
    }
    setRecord(chkrecord) {
        if (chkrecord !== "") {
            if ("Error" in chkrecord) {
                this.setState({
                    loading: false,
                    page_error: true,
                    error: chkrecord.Error,
                });
            } else {
                var lst = [];
                for (
                    var i = 0;
                    i < chkrecord.checkList[2].importantRecords[0].imp_records.length;
                    i++
                ) {
                    chkrecord.checkList[2].importantRecords[0].imp_records[i].position =
                        i;
                    chkrecord.checkList[2].importantRecords[0].imp_records[i].ref = true;
                    for (
                        var j = 0;
                        j <
                        chkrecord.checkList[2].importantRecords[0].imp_records[i].records
                            .length;
                        j++
                    ) {
                        chkrecord.checkList[2].importantRecords[0].imp_records[i].records[
                            j
                        ].rec_chg = false;
                    }
                }

                var imp_record = [];
                var ier_button = [];
                if (
                    chkrecord.checkList[2].importantRecords.length > 0 &&
                    JSON.stringify(chkrecord.checkList[2].importantRecords[0]) !== "{}"
                ) {
                    imp_record = chkrecord.checkList[2].importantRecords[0].imp_records;
                    ier_button = chkrecord.checkList[2].importantRecords[1].button;
                }
                this.setState({
                    loading: false,
                    chk_record: chkrecord.checkList[0].checkListRecords[0].records,
                    chk_button: chkrecord.checkList[0].checkListRecords[1].button,
                    event_record1: chkrecord.checkList[1].eventRecords[0],
                    event_record: chkrecord.checkList[1].eventRecords[0].records,
                    evt_choice: chkrecord.checkList[1].eventRecords[1].choice,
                    evt_button: chkrecord.checkList[1].eventRecords[2].button,
                    imp_record: imp_record,
                    ier_button: ier_button,
                    // imp_record1: chkrecord.checkList[2].importantRecords[0],
                });
            }
        }
    }

    formChangefn(vl, index, index_in, ob, type, id, vrf) {
        var frecord = this.state.imp_record;
        frecord[index].records[index_in].rec_chg = true;
        if (type === "reference") {
            if (ob === "loom_table_id" || ob === "loomtable_id") {
                this.callFieldList(ob);
            }
            if (vrf === false) {
                frecord[index].records[index_in].record[1] = false;
            }
            if (vl.length > 2) {
                if (vrf === true) {
                    if (frecord[index].records[index_in].record[1].name === ob) {
                        frecord[index].records[index_in].record[1].value.value = vl;
                        frecord[index].records[index_in].record[1].value.id = id;
                        frecord[index].records[index_in].record[1].clicked = true;
                        var rfrcd2 = this.state.refrecord;
                        rfrcd2.record = [];
                        this.setState({ record: frecord, refrecord: rfrcd2 });
                        this.validationfn(vl, index, index_in, ob, type, id);
                    }
                } else {
                    if (frecord[index].records[index_in].record[1].name === ob) {
                        frecord[index].records[index_in].record[1].value.value = vl;
                        frecord[index].records[index_in].record[1].value.id = id;
                        this.setState({ record: frecord });
                    }
                    var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
                    veri += '{"tabvalue":"' + vl + '"}]}';
                    var token = localStorage.getItem("token");
                    var rff = [];
                    axios
                        .post(this.state.loca + "/loom/reference/record", veri.toString(), {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer " + token,
                            },
                        })
                        .then((resp) => {
                            const refrencercd = resp.data;
                            if (refrencercd !== "") {
                                if ("Error" in refrencercd) {
                                    this.setState({
                                        loading: false,
                                        page_error: true,
                                        error: refrencercd.Error,
                                    });
                                } else {
                                    rff = refrencercd.referenceRecordList[2].records;
                                }
                            }
                            var rf = { index: index, record: rff };
                            this.setState({ refrecord: rf });
                        });
                }
            } else {
                if (frecord[index].records[index_in].record[1].name === ob) {
                    frecord[index].records[index_in].record[1].value.value = vl;
                    frecord[index].records[index_in].record[1].value.id = id;
                    var rfrcd3 = this.state.refrecord;
                    rfrcd3.record = [];
                    this.setState({ record: frecord, refrecord: rfrcd3 });
                }
            }
        } else {
            if (frecord[index].records[index_in].record[1].name === ob) {
                frecord[index].records[index_in].record[1].value = vl;
                this.setState({ record: frecord });
            }
        }
    }

    validationfn(vl, index, index_in, ob, type, id) {
        var formrecord = this.state.imp_record;
        if (type === "reference") {
            if (vl !== "") {
                if (formrecord[index].records[index_in].clicked === true) {
                    formrecord[index].records[index_in].verified = "verified";
                } else {
                    var token = localStorage.getItem("token");
                    var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
                    veri += '{"tabvalue":"' + vl + '"}]}';
                    axios
                        .post(this.state.loca + "/loom/reference/verify", veri.toString(), {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer " + token,
                            },
                        })
                        .then((resp) => {
                            const refrencercd = resp.data;
                            if (refrencercd !== "") {
                                if ("Error" in refrencercd) {
                                    this.setState({
                                        loading: false,
                                        page_error: true,
                                        error: refrencercd.Error,
                                    });
                                } else {
                                    if (refrencercd.Result === "Unverified") {
                                        formrecord[index].records[index_in].verified = "unverified";
                                        this.setState({ record: formrecord });
                                    } else {
                                        formrecord[index].records[index_in].verified = "verified";
                                        var rfrcd = this.state.refrecord;
                                        rfrcd.record = [];
                                        this.setState({ record: formrecord, refrecord: rfrcd });
                                    }

                                    this.setState({ record: formrecord });
                                    return;
                                }
                            }
                        });
                }
            } else {
                formrecord[index].records[index_in].verified = "initial";
                this.setState({ record: formrecord });
            }
        } else {
            if (formrecord[index].records[index_in].name === ob) {
                if (vl !== "") {
                    formrecord[index].records[index_in].verified = this.fieldverify(
                        formrecord[index].records[index_in].type,
                        vl
                    );
                } else {
                    formrecord[index].records[index_in].verified = "initial";
                }
            }
            this.setState({ record: formrecord });
        }
    }

    async callbtnImp(obj, index) {
        var imp_rcd = obj;
        var id = imp_rcd.id;
        var lt = imp_rcd.loomtable;
        var rec = imp_rcd.records;
        var new_rec = [];
        for (let i = 0; i < rec.length; i++) {
            if (rec[i].rec_chg === true) {
                new_rec.push(rec[i]);
            }
        }
        var rcd = {
            importantRecords: { id: id, loomtable: lt, records: new_rec },
        };
        var token = localStorage.getItem("token");
        this.setState({ stateLoading: true });
        axios
            .post(this.state.loca + "/loom/save/checklist/imporant/record", rcd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                },
            })
            .then((resp) => {
                var record = resp.data;
                this.setRecord(record);

                this.setState({ stateLoading: false });
                this.getImportantEventRecord();
                this.handleOpenSnackBar("success", "Important Event updated");
            });
    }

    async callEventbtn() {
        var chc = this.state.evt_choice;
        console.log("changedEventRecord", this.state.changedEventRecord);

        var rcd = {
            eventRecords: { record: this.state.changedEventRecord, choice: chc },
        };
        var token = localStorage.getItem("token");
        this.setState({ evtLoading: true });
        axios
            .post(this.state.loca + "/loom/save/checklist/event/record", rcd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                },
            })
            .then(
                (resp) => {
                    var record = resp.data;
                    this.setRecord(record);
                    this.setState({ evtLoading: false });
                    this.setState({ changedEventRecord: [] });
                    this.getActiveEvent();
                    this.handleOpenSnackBar("success", "Event updated");
                },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                }
            );
    }

    async callCheckListbtn() {
        var array = [];
        for (var c = 0; c < this.state.chk_record.length; c++) {
            if ("button" in this.state.chk_record[c]) {
            } else {
                array.push(this.state.chk_record[c]);
            }
        }
        var chk = { checkListRecords: array };
        var token = localStorage.getItem("token");
        this.setState({ chekLoading: true });
        axios
            .post(this.state.loca + "/loom/save/checklist/record", chk, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                },
            })
            .then(
                (resp) => {
                    var record = resp.data;
                    this.setState({ chekLoading: false });
                    var msg = record.Message;
                    if (msg !== "") {
                        // this.setState({ page_message: true, message: msg });
                        this.handleOpenSnackBar("success", "Check list updated");
                    }
                },
                (error) => {
                    this.props.showErrorCompo();
                    console.log(error);
                }
            );
    }

    changeState(e, index) {
        var evt_rcd = this.state.event_record;
        evt_rcd[index].state = e.target.value;
        let vt = [... this.state.changedEventRecord];
        let already = vt.find((obj) => obj.id === evt_rcd[index].id);
        console.log("already", already);

        if (already)
            already.state = e.target.value;
        else
            vt.push(evt_rcd[index]);
        console.log("vt", vt);

        this.setState({ event_record: evt_rcd, changedEventRecord: vt });
    }

    changeValue(e, index) {
        var chk_rcd = this.state.chk_record;
        chk_rcd[index].flag_set = e.target.checked.toString();
        this.setState({ chk_record: chk_rcd });
    }

    setOpen(i, rf) {
        const rcd = this.state.imp_record;
        rcd[i].ref = !rf;
        this.setState({ imp_record: rcd });
    }

    clickRefrence(e, id, name, type, index, index_in, label) {
        e.preventDefault();
        this.setState({
            columnId: id,
            objName: name,
            objType: type,
            objIndex: index,
            index_in: index_in,
            objLabel: label,
            showModelList: true,
        });
    }

    setRefrecord(vl, index, index_in, ob, type, id) {
        this.formChangefn(vl, index, index_in, ob, type, id, true);
    }

    fieldverify(type, vl) {
        if (type === "String") {
            return "verified";
        }

        if (type === "email") {
            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
                return "verified";
            } else {
                return "unverified";
            }
        }
        if (type === "int") {
            if (/^[0-9]*[0-9][0-9]*$/.test(vl)) {
                return "verified";
            } else {
                return "unverified";
            }
        }
        if (type === "date") {
            if (
                /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
                    vl
                )
            ) {
                return "verified";
            } else {
                return "unverified";
            }
        }
        if (type === "reference") {
            if (/[a-zA-Z]/g.test(vl)) {
                return "verified";
            } else {
                return "unverified";
            }
        }
        if (type === "filter") {
            if (/[a-zA-Z]/g.test(vl)) {
                return "verified";
            } else {
                return "unverified";
            }
        }
    }

    render() {

        return (
            <div>
                {this.state.relation_loading === true ? (
                    <WorkInProgressSmall></WorkInProgressSmall>
                ) : (
                    <div>
                        {true && (
                            <div>
                                {true ? (
                                    <div>
                                        <div className="form_tab  fixdive">
                                            <Tab.Container
                                                id="left-tabs-example"
                                                defaultActiveKey={
                                                    "Check List"
                                                }

                                            >
                                                <Nav
                                                    variant="pills"
                                                    className="flex-column ownNav"
                                                >

                                                    <div className="d-flex rw flex-wrap">
                                                        {this.state.allCard.map(
                                                            (nama, tab_ia) => (
                                                                <div
                                                                    md={2}
                                                                    key={tab_ia}
                                                                    className="p-0 text-center"
                                                                >
                                                                    {this.state.isMobile ? (
                                                                        <Nav.Item className="cur">
                                                                            <Nav.Link
                                                                                eventKey={
                                                                                    nama
                                                                                }
                                                                            >
                                                                                {nama}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    ) : (
                                                                        <Nav.Item className="cur">
                                                                            <Nav.Link
                                                                                eventKey={
                                                                                    nama
                                                                                }
                                                                            >
                                                                                {nama}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </Nav>
                                                <Tab.Content>
                                                    {this.state.allCard.map(
                                                        (nam, tab_i) => (
                                                            <Tab.Pane
                                                                key={tab_i}
                                                                eventKey={
                                                                    nam
                                                                }
                                                            >

                                                                <div className={chk.pagesetup}>
                                                                    {this.state.loading === true ? (
                                                                        <WorkInProgress></WorkInProgress>
                                                                    ) : (
                                                                        <div>
                                                                            {this.state.page_error === true && (
                                                                                <div className={`alert alert-danger  ${chk.form_alt}`} style={{ margin: "0.50rem", color: "black" }} role="alert">
                                                                                    {this.state.error}
                                                                                </div>
                                                                            )}
                                                                            {this.state.page_message === true && (
                                                                                <div className={`alert alert-success ${chk.form_alt}`} style={{ margin: "0.50rem", color: "black" }} role="alert">
                                                                                    {this.state.message}
                                                                                </div>
                                                                            )}
                                                                            {nam === "Check List" && this.checkList()}
                                                                            {nam === "Event" && this.Event()}
                                                                            {nam === "Important Record" && this.ImportantEventRecord()}

                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </Tab.Pane>
                                                        )
                                                    )}
                                                </Tab.Content>
                                            </Tab.Container>

                                            <Snackbar open={this.state.openSnackBar} autoHideDuration={5000}
                                             onClose={this.handleCloseSnackBar}
                                             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            //  sx={{ top: '100px' }}  // Custom top offset
                                             >
                                                <Alert
                                                    onClose={this.handleCloseSnackBar}
                                                    /*  severity="success"  severity="warning" severity="info"
*/
                                                    severity={this.state.severity}
                                                    variant="filled"
                                                    sx={{ width: '100%' }}
                                                >
                                                    {this.state.snacKbarMsg}
                                                </Alert>

                                            </Snackbar>
                                        </div>


                                    </div>


                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    checkList = () => {
        console.log("dan1");

        return (


            <div className={chk.checklist_outerline}>
                <div className="check-header d-flex justify-content-between align-items-center">
                    <strong className="check-title">Checklist</strong>
                    <button className="btn btn-primary check-save-btn"

                        onClick={this.callCheckListbtn}
                    >Save</button>
                </div>

                <div className="check-card">
                    <table className="table table-striped table-sm">
                        {this.state.chekLoading && <WorkInProgressSmall />}
                        <tbody className="check-tbody">
                            <tr className="check-table-header">
                                <th width="25%">Id</th>
                                <th>Name</th>
                                <th>Flag Set</th>
                                <th></th>
                            </tr>
                            {this.state.chk_record.length === 0 && (
                                <tr className="check-table-row">
                                    <td className="check-no-record" colSpan="4">No record found</td>
                                </tr>
                            )}
                            {this.state.chk_record.map((obj_c, index_c) => (
                                <tr className="check-table-row" key={index_c}>
                                    <td>{obj_c.id}</td>
                                    <td>{obj_c.name}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={obj_c.flag_set === "true"}
                                            onChange={(e) => this.changeValue(e, index_c)}
                                            style={{ width: "20px", height: "20px" }}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>



        )
    }

    checkList1 = () => {



        return (
            <div className="custom-card mb-3">
                <div className=" custom-card-header">

                    <strong>
                        {"Check List"}
                    </strong>

                </div>
                <div className="custom-card-body" >
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Flag Set</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.chk_record?.length === 0 && (
                                <tr>
                                    <td style={{ borderBottom: 0 }}>No record found</td>
                                </tr>
                            )}
                            {this.state.chk_record?.map((obj_in, index_in) => (
                                <tr key={index_in}>
                                    <td


                                    >
                                        {obj_in.id}
                                    </td>
                                    <td>{obj_in.name}</td>
                                    <td>      <input
                                        type="checkbox"
                                        checked={obj_in.flag_set === "true" ? true : false}
                                        onChange={(e) => this.changeValue(e, index_in)}
                                    ></input></td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>


        )
    }

    Event = () => {
        return (
            <div className={chk.checklist_outerline}
            >
                <div className="check-header d-flex justify-content-between align-items-center">
                    <strong className="check-title">Event</strong>
                    <button className="btn btn-primary check-save-btn"
                        onClick={(e) => this.callEventbtn()}
                    >Save</button>
                </div>

                <div className="check-card">
                    <table className="table table-striped table-sm">
                        {this.state.evtLoading && <WorkInProgressSmall />}
                        <tbody className="check-tbody">
                            <tr className="check-table-header">
                                <th width="25%">Id</th>
                                <th>Event</th>
                                <th>State</th>

                            </tr>
                            {this.state.event_record?.length === 0 && (
                                <tr className="check-table-row">
                                    <td className="check-no-record" colSpan="4">No record found</td>
                                </tr>
                            )}
                            {this.state.event_record.map((obj_c, index_c) => (
                                <tr className="check-table-row" key={index_c}>
                                    <td>{obj_c.id}</td>
                                    <td>{obj_c.event_id.value}</td>
                                    <td>
                                        <select
                                            style={{ width: "auto" }}
                                            className={`form-control form-select `}
                                            aria-label="Default"
                                            value={obj_c.state}
                                            onChange={(e) => this.changeState(e, index_c)}
                                        >
                                            {this.state.evt_choice.map((obj2, index2) => (
                                                <option key={index2} value={obj2.value}>
                                                    {obj2.value}
                                                </option>
                                            ))}
                                        </select>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
    Event1 = () => {
        console.log("dan2");
        return (<div className={chk.checklist_outerline}>
            <strong>Event</strong>
            <table className="table table-striped table-sm">
                {this.state.evtLoading ? (
                    <WorkInProgressSmall></WorkInProgressSmall>
                ) : null}
                <tbody className={chk.event_position}>
                    <tr>
                        <th width="25%">Id</th>
                        {/* <th>Name</th> */}
                        <th>Event</th>
                        <th>State</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                    {this.state.event_record?.length === 0 && (
                        <tr>
                            <td style={{ borderBottom: 0 }}>No record found</td>
                        </tr>
                    )}
                    {this.state.event_record?.length > 0 &&
                        this.state.event_record?.map((obj, index) => (
                            <tr key={index}>
                                <td className="val_pad val_under vlpointer">
                                    {obj.id}
                                </td>
                                {/* <td>{obj.name}</td> */}
                                <td>{obj.event_id.value}</td>
                                <td>
                                    {/*    <h1>{obj.state}</h1> */}
                                    <select
                                        className={`form-select ${chk.evtinputlist}`}
                                        aria-label="Default"
                                        value={obj.state}
                                        onChange={(e) => this.changeState(e, index)}
                                    >
                                        {this.state.evt_choice.map((obj2, index2) => (
                                            <option key={index2} value={obj2.value}>
                                                {obj2.value}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                {/* Event save button */}
                                <td>
                                    {this.state.evt_button.map((btn, btn_i) => (
                                        <button
                                            className={`${chk.csm_btn} ${chk.csm_btn_pri} col-md-5 sub-btn`}
                                            key={btn_i}
                                            onClick={(e) => this.callEventbtn(obj)}
                                        >
                                            {btn.name}
                                        </button>
                                    ))}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>)
    }

    ImportantEventRecord = () => {

        return (<div /* className={`${chk.checklist_outerline}`} */>
            <div>
                {this.state.stateLoading === true ? (
                    <WorkInProgressSmall></WorkInProgressSmall>
                ) : null}
            </div>

            <div className="check-header d-flex justify-content-between align-items-center">
                <strong className="check-title">Important Records</strong>

            </div>
            <div>
                {this.state.imp_record?.length === 0 && (
                    <div>No record found</div>
                )}

                {this.state.imp_record?.length > 0 &&
                    this.state.imp_record.map((obj, index_m) => (
                        <div key={index_m} className={chk.checklist_outerline}
                        >
                            <div className="check-header d-flex justify-content-between align-items-center">
                                <strong className="check-title"> {obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}</strong>
                                <button className="btn btn-primary check-save-btn"
                                    onClick={(e) =>
                                        this.callbtnImp(obj, index_m)
                                    }

                                >Save</button>

                                <button
                                    className={`btn ${obj.ref ? 'btn-secondary' : 'btn-outline-secondary'} check-toggle-btn`}
                                    onClick={(pr) => this.setOpen(obj.position, obj.ref)}

                                >
                                    {obj.ref ? 'Close' : 'Open'}
                                </button>


                            </div>

                            {obj.ref && <div className="check-card">
                                <table className="table table-striped table-sm" style={{ whiteSpace: "nowrap" }}>
                                    <tbody className="check-tbody">
                                        <tr className="check-table-header">
                                            <th width="25%" style={{ textAlign: "center", verticalAlign: "middle" }}>Id</th>
                                            <th style={{ textAlign: "center", verticalAlign: "middle" }}>Name</th>
                                            <th style={{ textAlign: "center", verticalAlign: "middle" }}>{"Date"}</th>
                                            <th style={{ textAlign: "center", verticalAlign: "middle" }}>{obj.replyColumn}</th>
                                        </tr>

                                        {obj.records?.length === 0 && (
                                            <tr className="check-table-row">
                                                <td className="check-no-record" colSpan="4">No record found</td>
                                            </tr>
                                        )}
                                        {obj.records.map((obj_in, index_in) => (
                                            <tr/*  className="check-table-row"  */ style={{ textAlign: "center", verticalAlign: "middle" }} key={index_in}>
                                                <td>{obj_in.id}</td>
                                                <td>{obj_in.name}</td>
                                                <td>{obj_in.date}</td>
                                                <td>
                                                    {obj_in.record[1].type ===
                                                        "String" ? (
                                                        <div /* className="form-group objpdg " */
                                                            /*    style={{width:"auto"}}  */
                                                            className={`form-control form-select `}

                                                        >
                                                            {/*     <span>
                                                                            {obj_in.record[1].label}
                                                                        </span> */}
                                                            <input
                                                                type="text"
                                                                value={obj_in.record[1].value}
                                                                readOnly={
                                                                    obj_in.record[1].uivalid
                                                                        .read_only === "true"
                                                                }
                                                                maxLength={
                                                                    obj_in.record[1].uivalid
                                                                        .max_length
                                                                }
                                                                onChange={(e) =>
                                                                    this.formChangefn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id,
                                                                        false
                                                                    )
                                                                }
                                                                onMouseOut={(e) =>
                                                                    this.validationfn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type === "int" ? (
                                                        <div/*  className="form-group" */

                                                        /*        className={`form-group`} */

                                                        >

                                                            <input
                                                                className="form-control"
                                                                style={{ width: "auto" }}

                                                                type="text"
                                                                value={obj_in.record[1].value}
                                                                readOnly={
                                                                    obj_in.record[1].uivalid
                                                                        .read_only === "true"
                                                                }
                                                                maxLength={
                                                                    obj_in.record[1].uivalid
                                                                        .max_length
                                                                }
                                                                onChange={(e) =>
                                                                    this.formChangefn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id,
                                                                        false
                                                                    )
                                                                }
                                                                onMouseOut={(e) =>
                                                                    this.validationfn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type === "date" ? (
                                                        <div >
                                                            {/*      <span>
                                                                            {obj_in.record[1].label}
                                                                        </span> */}
                                                            <input
                                                                className="form-control"
                                                                style={{ width: "auto" }}
                                                                type="date"
                                                                value={obj.record.value}
                                                                readOnly={
                                                                    obj_in.record[1].uivalid
                                                                        .read_only === "true"
                                                                }
                                                                maxLength={
                                                                    obj_in.record[1].uivalid
                                                                        .max_length
                                                                }
                                                                onChange={(e) =>
                                                                    this.formChangefn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id,
                                                                        false
                                                                    )
                                                                }
                                                                onMouseOut={(e) =>
                                                                    this.validationfn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type ===
                                                        "datetime" ? (
                                                        <div >
                                                            {/*       <span>
                                                                            {obj_in.record[1].label}
                                                                        </span> */}
                                                            <input
                                                                className="form-control"
                                                                style={{ width: "auto" }}
                                                                type="datetime-local"
                                                                step="1"
                                                                value={obj_in.record[1].value}
                                                                readOnly={
                                                                    obj_in.record[1].uivalid
                                                                        .read_only === "true"
                                                                }
                                                                maxLength={
                                                                    obj_in.record[1].uivalid
                                                                        .max_length
                                                                }
                                                                onChange={(e) =>
                                                                    this.formChangefn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id,
                                                                        false
                                                                    )
                                                                }
                                                                onMouseOut={(e) =>
                                                                    this.validationfn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type ===
                                                        "boolean" ? (
                                                        <div className={`form-check ${chk.fmcheck}`}>
                                                            <input
                                                                type="checkbox"
                                                                className={
                                                                    obj.verified === "unverified"
                                                                        ? `${chk.checkpadd} ${chk.unverifi}`
                                                                        : chk.checkpadd
                                                                }
                                                                checked={
                                                                    obj_in.record[1].value ===
                                                                        "true"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e) =>
                                                                    this.formChangefn(
                                                                        e.target.checked.toString(),
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id,
                                                                        false
                                                                    )
                                                                }
                                                                onMouseOut={(e) =>
                                                                    this.validationfn(
                                                                        e.target.value,
                                                                        index_m,
                                                                        index_in,
                                                                        obj_in.record[1].name,
                                                                        obj_in.record[1].type,
                                                                        obj_in.record[1].id
                                                                    )
                                                                }
                                                            ></input>
                                                            {/*          <span>
                                                                            {obj_in.record[1].label}
                                                                        </span> */}
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type ===
                                                        "choice" ? (
                                                        <div className="form-group">

                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <select
                                                                        style={{ width: "auto" }}
                                                                        className={`form-control form-select `}
                                                                        aria-label="Default select example"
                                                                        value={
                                                                            obj_in.record[1].value
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.formChangefn(
                                                                                e.target.value,
                                                                                index_m,
                                                                                index_in,
                                                                                obj_in.record[1].name,
                                                                                obj_in.record[1].type,
                                                                                obj_in.record[1].id,
                                                                                false
                                                                            )
                                                                        }
                                                                        onMouseOut={(e) =>
                                                                            this.validationfn(
                                                                                e.target.value,
                                                                                index_m,
                                                                                index_in,
                                                                                obj_in.record[1].name,
                                                                                obj_in.record[1].type,
                                                                                obj_in.record[1].id
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value="None">
                                                                            None
                                                                        </option>
                                                                        {obj_in.record[1].choice.map(
                                                                            (ch, chi) => (
                                                                                <option
                                                                                    key={chi}
                                                                                    value={ch.name}
                                                                                >
                                                                                    {ch.value}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                {<div className="col-md-3">

                                                                </div>}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {obj_in.record[1].type ===
                                                        "reference" ? (
                                                        <div>
                                                            <div className="form-group">
                                                                {/*        <span>
                                                                                {obj_in.record[1].label}
                                                                            </span> */}
                                                                <div
                                                                    style={{ display: "flex" }}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        style={{ width: "auto" }}
                                                                        value={
                                                                            obj_in.record[1].value
                                                                                .name
                                                                        }
                                                                        readOnly={
                                                                            obj_in.record[1].uivalid
                                                                                .read_only === "true"
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.formChangefn(
                                                                                e.target.value,
                                                                                index_m,
                                                                                index_in,
                                                                                obj_in.record[1].name,
                                                                                obj_in.record[1].type,
                                                                                obj_in.record[1].id,
                                                                                false
                                                                            )
                                                                        }
                                                                        onMouseOut={(e) => {
                                                                            // validationfn(
                                                                            //   e.target.value,
                                                                            //   index,
                                                                            //   index_in,
                                                                            //   obj_in.record[1].name,
                                                                            //   obj_in.record[1].type,
                                                                            //   obj_in.record[1].id
                                                                            // )
                                                                        }}
                                                                    ></input>
                                                                    <div className={`${chk.btnsrc} ${chk.vlpointer}`}>
                                                                        <i
                                                                            className="fa fa-search"
                                                                            onClick={(e) => {
                                                                                this.clickRefrence(
                                                                                    e,
                                                                                    obj_in.record[1].id,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].label
                                                                                );

                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.refrecord.index ===
                                                                index_m &&
                                                                this.state.refrecord.record
                                                                    .length > 0 && (
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                        }}
                                                                    >
                                                                        {this.state.refrecord.record.map(
                                                                            (obj_ref, or_i) => (
                                                                                <div
                                                                                    className={chk.refrcd}
                                                                                    onClick={(e) => {
                                                                                        this.setRefrecord(
                                                                                            obj_ref.value,
                                                                                            index_m,
                                                                                            index_in,
                                                                                            obj_in.record[1]
                                                                                                .name,
                                                                                            obj_in.record[1]
                                                                                                .type,
                                                                                            obj_ref.id
                                                                                        );
                                                                                    }}
                                                                                    key={or_i}
                                                                                >
                                                                                    {obj_ref.value}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    ) : null}
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    ))}
            </div>
        </div>)
    }

    ImportantEventRecord1 = () => {
        console.log("dan3");
        return (<div className={`${chk.checklist_outerline}`}>
            <div>
                {this.state.stateLoading === true ? (
                    <WorkInProgressSmall></WorkInProgressSmall>
                ) : null}
            </div>
            <div className="mb-3">
                <strong>Important Records</strong>
            </div>
            <div className="check-header d-flex justify-content-between align-items-center">
                <strong className="check-title">Important Records</strong>

            </div>
            <div>
                {this.state.imp_record?.length === 0 && (
                    <div>No record found</div>
                )}

                {this.state.imp_record?.length > 0 &&
                    this.state.imp_record.map((obj, index_m) => (
                        <div key={index_m}>
                            <strong key={index_m}>
                                {obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
                            </strong>
                            <button
                                type="button"
                                className={
                                    obj.ref
                                        ? chk.imp_record_dropdown_btn
                                        : chk.imp_record_dropdown_btnp
                                }
                                onClick={(pr) => this.setOpen(obj.position, obj.ref)}
                            >
                                {obj.ref ? "-" : "+"}
                            </button>
                            <div>
                                {obj.ref === true && (
                                    <div>
                                        <table className={`table table-striped table-hover table-sm ${chk.imp_rec}`}>
                                            <tbody
                                                className={chk.important_record_position}
                                                style={{ textAlign: "ceneter" }}
                                            >
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Date</th>
                                                    <th>Value</th>

                                                </tr>
                                                {obj.records.length === 0 ? (
                                                    <tr className={chk.tableStyle}>
                                                        <td style={{ borderBottom: 0 }}>No record found</td>
                                                    </tr>
                                                ) : (
                                                    obj.records.map((obj_in, index_in) => (
                                                        <tr key={index_in}>
                                                            <td>{obj_in.id}</td>
                                                            <td>{obj_in.name}</td>
                                                            <td>{obj_in.date}</td>
                                                            <td>
                                                                {obj_in.record[1].type ===
                                                                    "String" ? (
                                                                    <div className="form-group objpdg ">
                                                                        <span>
                                                                            {obj_in.record[1].label}
                                                                        </span>
                                                                        <input
                                                                            type="text"
                                                                            value={obj_in.record[1].value}
                                                                            readOnly={
                                                                                obj_in.record[1].uivalid
                                                                                    .read_only === "true"
                                                                            }
                                                                            maxLength={
                                                                                obj_in.record[1].uivalid
                                                                                    .max_length
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.formChangefn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id,
                                                                                    false
                                                                                )
                                                                            }
                                                                            onMouseOut={(e) =>
                                                                                this.validationfn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id
                                                                                )
                                                                            }
                                                                        ></input>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type === "int" ? (
                                                                    <div className="form-group">
                                                                        <span>
                                                                            {obj_in.record[1].label}
                                                                        </span>
                                                                        <input
                                                                            type="text"
                                                                            value={obj_in.record[1].value}
                                                                            readOnly={
                                                                                obj_in.record[1].uivalid
                                                                                    .read_only === "true"
                                                                            }
                                                                            maxLength={
                                                                                obj_in.record[1].uivalid
                                                                                    .max_length
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.formChangefn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id,
                                                                                    false
                                                                                )
                                                                            }
                                                                            onMouseOut={(e) =>
                                                                                this.validationfn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id
                                                                                )
                                                                            }
                                                                        ></input>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type === "date" ? (
                                                                    <div className=" form-group">
                                                                        <span>
                                                                            {obj_in.record[1].label}
                                                                        </span>
                                                                        <input
                                                                            type="date"
                                                                            value={obj.record.value}
                                                                            readOnly={
                                                                                obj_in.record[1].uivalid
                                                                                    .read_only === "true"
                                                                            }
                                                                            maxLength={
                                                                                obj_in.record[1].uivalid
                                                                                    .max_length
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.formChangefn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id,
                                                                                    false
                                                                                )
                                                                            }
                                                                            onMouseOut={(e) =>
                                                                                this.validationfn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id
                                                                                )
                                                                            }
                                                                        ></input>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type ===
                                                                    "datetime" ? (
                                                                    <div className=" form-group">
                                                                        <span>
                                                                            {obj_in.record[1].label}
                                                                        </span>
                                                                        <input
                                                                            type="datetime-local"
                                                                            step="1"
                                                                            value={obj_in.record[1].value}
                                                                            readOnly={
                                                                                obj_in.record[1].uivalid
                                                                                    .read_only === "true"
                                                                            }
                                                                            maxLength={
                                                                                obj_in.record[1].uivalid
                                                                                    .max_length
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.formChangefn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id,
                                                                                    false
                                                                                )
                                                                            }
                                                                            onMouseOut={(e) =>
                                                                                this.validationfn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id
                                                                                )
                                                                            }
                                                                        ></input>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type ===
                                                                    "boolean" ? (
                                                                    <div className={`form-check ${chk.fmcheck}`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={
                                                                                obj.verified === "unverified"
                                                                                    ? `${chk.checkpadd} ${chk.unverifi}`
                                                                                    : chk.checkpadd
                                                                            }
                                                                            checked={
                                                                                obj_in.record[1].value ===
                                                                                    "true"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.formChangefn(
                                                                                    e.target.checked.toString(),
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id,
                                                                                    false
                                                                                )
                                                                            }
                                                                            onMouseOut={(e) =>
                                                                                this.validationfn(
                                                                                    e.target.value,
                                                                                    index_m,
                                                                                    index_in,
                                                                                    obj_in.record[1].name,
                                                                                    obj_in.record[1].type,
                                                                                    obj_in.record[1].id
                                                                                )
                                                                            }
                                                                        ></input>
                                                                        <span>
                                                                            {obj_in.record[1].label}
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type ===
                                                                    "choice" ? (
                                                                    <div className="form-group">

                                                                        <div className="row">
                                                                            <div className="col-md-7">
                                                                                <select
                                                                                    type="checkbox"
                                                                                    /*      className={`form-control form-select ${chk.formpadd}`} */
                                                                                    aria-label="Default select example"
                                                                                    value={
                                                                                        obj_in.record[1].value
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        this.formChangefn(
                                                                                            e.target.value,
                                                                                            index_m,
                                                                                            index_in,
                                                                                            obj_in.record[1].name,
                                                                                            obj_in.record[1].type,
                                                                                            obj_in.record[1].id,
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    onMouseOut={(e) =>
                                                                                        this.validationfn(
                                                                                            e.target.value,
                                                                                            index_m,
                                                                                            index_in,
                                                                                            obj_in.record[1].name,
                                                                                            obj_in.record[1].type,
                                                                                            obj_in.record[1].id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="None">
                                                                                        None
                                                                                    </option>
                                                                                    {obj_in.record[1].choice.map(
                                                                                        (ch, chi) => (
                                                                                            <option
                                                                                                key={chi}
                                                                                                value={ch.name}
                                                                                            >
                                                                                                {ch.value}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <span>
                                                                                    {obj_in.record[1].label}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {obj_in.record[1].type ===
                                                                    "reference" ? (
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <span>
                                                                                {obj_in.record[1].label}
                                                                            </span>
                                                                            <div
                                                                                style={{ display: "flex" }}
                                                                            >
                                                                                <input
                                                                                    type="text"
                                                                                    value={
                                                                                        obj_in.record[1].value
                                                                                            .name
                                                                                    }
                                                                                    readOnly={
                                                                                        obj_in.record[1].uivalid
                                                                                            .read_only === "true"
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        this.formChangefn(
                                                                                            e.target.value,
                                                                                            index_m,
                                                                                            index_in,
                                                                                            obj_in.record[1].name,
                                                                                            obj_in.record[1].type,
                                                                                            obj_in.record[1].id,
                                                                                            false
                                                                                        )
                                                                                    }

                                                                                ></input>
                                                                                <div className={`${chk.btnsrc} ${chk.vlpointer}`}>
                                                                                    <i
                                                                                        className="fa fa-search"
                                                                                        onClick={(e) => {
                                                                                            this.clickRefrence(
                                                                                                e,
                                                                                                obj_in.record[1].id,
                                                                                                obj_in.record[1].name,
                                                                                                obj_in.record[1].type,
                                                                                                index_m,
                                                                                                index_in,
                                                                                                obj_in.record[1].label
                                                                                            );

                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.refrecord.index ===
                                                                            index_m &&
                                                                            this.state.refrecord.record
                                                                                .length > 0 && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                    }}
                                                                                >
                                                                                    {this.state.refrecord.record.map(
                                                                                        (obj_ref, or_i) => (
                                                                                            <div
                                                                                                className={chk.refrcd}
                                                                                                onClick={(e) => {
                                                                                                    this.setRefrecord(
                                                                                                        obj_ref.value,
                                                                                                        index_m,
                                                                                                        index_in,
                                                                                                        obj_in.record[1]
                                                                                                            .name,
                                                                                                        obj_in.record[1]
                                                                                                            .type,
                                                                                                        obj_ref.id
                                                                                                    );
                                                                                                }}
                                                                                                key={or_i}
                                                                                            >
                                                                                                {obj_ref.value}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                ) : null}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                )}
                                                <div className="mt-1">
                                                    {this.state.ier_button.map((btn, btn_i) => (
                                                        <div>
                                                            <button
                                                                className="csm_btn csm_btn_pri col-md-5 sub-btn mx-1"
                                                                key={btn_i}
                                                                onClick={(e) =>
                                                                    this.callbtnImp(obj, btn_i)
                                                                }
                                                            >
                                                                {btn.name}
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>)
    }
}

export default CheckList_CompoNew;
