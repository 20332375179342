import React, { Component } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Button, Modal as Mod } from "react-bootstrap";
import "../css/mainCompo.css";
import Dashboard from "./dashboard";
import FormComponent from "./form_component";
import Login from "./login";
import SortComponent from "./sort_component";
import ListComponent from "./list_component";
import LeftNav from "./left_navigation";
import ReportComponent from "./report_component";
import RegisterComponent from "./register_component";
import MultiInsert from "./multi_insert";
import Multipage from "./multi_page";
import ClientRegistration from "./client_registration";
import PropertiesComponent from "./properties_component";
import OwnerPreference from "./ownerPrefrence";
import ThemeDesignComponent from "./themedesign_component";
import FormViewComponent from "./formview_component";
import MultiTableRelation from "./multiTableRelation";
import CheckListComponent from "./checkList_component";
import ForgetPassword from "./ForgetPassword";
import GenerateNewPin from "./generateNewPin";
import SetNewPassword from "./setNewPassword";
import OtpVerification from "./OtpVerification";
import ClientInfoCompo from "./clientInfoCompo";
import ClientInfoRecCompo from "./clientInfoRecCompo";
import RoleSelectionComponent from "./roleSelectionComponent";
import HtmlPageComponent from "./htmlPage";
import UserNotificationCompo from "./userNotification";
import LoomStatusComponent from "./loomstatus";
import IncomeExpense from "./incomeExpense";
import InventoryComponent from "./inventory";
import UiPageComponent from "./uipage_component";
import ImpersonationCompo from "./impersonationCompo";
import VarBlankCompo from "./VarBlankCompo";
import TabularReportCompo from "./tabularReport";
import ViewTabularReport from "./viewTabularReport";
import MaterialComponent from "./materialComponent";
import WorkerInfoCompo from "./workerInfoCompo";
import SalaryCompo from "./salary_compo";
import ErrorPage from "./errorPage";
import InitialSetupComponent from "./InitialSetupComponent";
import ReportComponentNew from "./ReportComponentNew";
import UserInfo from "./UserInfo";

import { ToastContainer } from "react-toastify";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PrivateCompo from "./privateCompo";
import GlobalContext from "./GlobalContext ";
import UserNotification from "./userNotification";
import { AppConfig } from "./AppConfig";
import MultipleApplicationRequest from "./MultipleApplicationRequest";
import CheckList_CompoNew from "./CheckList_CompoNew";
import TabularReportNew from "./TabularReportNew";
import IncomeExpense2 from "./IncomeExpense2";

class MainCompo extends Component {
  state = {
    // pageHeight: 0,
    showMain: false,
    showlogin: true,
    showRegister: false,
    showLoginCompo: true,
    callDash: false,
    showSortedCompo: false,
    showListCompo: false,
    showFormCompo: false,
    sorted_name: "",
    url: "",
    showLeftPane: false,
    showReportCompo: false,
    showReportCompoNew: false,
    showMultiInsert: false,
    showMultiPage: false,
    showClientReg: false,
    height: "800px",
    width: "",
    /* isMobile: false, */
    heightTop: "50px",
    tabname: "",
    rid: "",
    formRecordType: "",
    showPropCompo: false,
    showOwnerPrefComp: false,
    showThemeCompo: false,
    showViewCompo: false,
    showMultiTable: false,
    showChecklistCompo: false,
    showForgetPassword: false,
    showGenerateNewPin: false,
    showOtpVerification: false,
    showNewPassword: false,
    showClientInfo: false,
    showClientInfoRec: false,
    showRoleSelectionCompo: false,
    showHtmlPage: false,
    showMainCompo: false,
    showNotificationCompo: false,
    showUiPageCompo: false,
    showVarBlankCompo: false,
    showVariableCompo: false,
    showStatusCompo: false,
    showTabularReport: false,
    showViewTabularReport: false,
    showInventoryCompo: false,
    showInitialSetupCompo: false,
    showMaterialCompo: false,
    showIncomeExpense: false,
    showWorkerInfo: false,
    showViewSalary: false,
    showErrorCompo: false,
    showUserInfoCompo: false,
    contxtMenu: [],
    counter1: 0,
    counter2: 0,
    backLastIndex: 1,
    filter: "",
    timeline: "",
    mt_name: "",
    language: "English",
    registerType: "",
    op: "",
    un: "",
    i_d: "",
    rt: "",
    isOpen: false,
    username: "",
    userinfo: {},
    landingPref: {},
    html: "",
    script: "",
    msg: [],
    cnt: "",
    record: [],
    name: "",
    loca: AppConfig.loca,
    test: "gauhar",
    adminPanel: false,
    isMobile: false,
    reload: "",
    setRecord: {},
    report_name: "",
    impersonate_name: "",
    loading: true,
    contextMenu: [],
    labelContext: [],
    location: "",
    taskName: "",
    authenticated: 2,
    directExpoToken: "",
    deviceId: "",
    version: "",
    build: "",
    newData: "",
    impersonateInfo: {},
    // headHeight: 0,
    formHeight: 0,
    isImpersonate: false,
  };

  constructor() {
    super();
    // this.handleResize = this.handleResize.bind(this);

    this.myRef = React.createRef();
    this.mainTop = React.createRef();
    /*   this.isMobile=React.createRef() */
    this.loading = React.createRef(false);

    this.showLogin = this.showLogin.bind(this);

    this.handleDashboard = this.handleDashboard.bind(this);

    this.showSortCompo = this.showSortCompo.bind(this);
    this.handleSortCompo = this.handleSortCompo.bind(this);

    this.showListCompo = this.showListCompo.bind(this);
    this.handleListCompo = this.handleListCompo.bind(this);

    this.showFormCompo = this.showFormCompo.bind(this);
    this.handleFormCompo = this.handleFormCompo.bind(this);

    this.showPropCompo = this.showPropCompo.bind(this);

    this.showOtherCompo = this.showOtherCompo.bind(this);
    this.handleOtherCompo = this.handleOtherCompo.bind(this);

    this.showOwnerPrefComp = this.showOwnerPrefComp.bind(this);
    this.handleOwnerPrefComp = this.handleOwnerPrefComp.bind(this);

    this.showMultiCompo = this.showMultiCompo.bind(this);
    this.handleMultiCompo = this.handleMultiCompo.bind(this);

    this.showReportCompo = this.showReportCompo.bind(this);
    this.handleReportCompo = this.handleReportCompo.bind(this);

    this.showReportCompoNew = this.showReportCompoNew.bind(this);
    this.handleReportCompoNew = this.handleReportCompoNew.bind(this);

    this.showMultiPage = this.showMultiPage.bind(this);
    this.handleMultiPage = this.handleMultiPage.bind(this);

    this.showClientReg = this.showClientReg.bind(this);
    this.handleClientReg = this.handleClientReg.bind(this);

    this.registerCompo = this.registerCompo.bind(this);
    this.handleRegisterCompo = this.handleRegisterCompo.bind(this);

    this.handleLeftNavCompo = this.handleLeftNavCompo.bind(this);

    this.showThemeCompo = this.showThemeCompo.bind(this);
    this.handleThemeCompo = this.handleThemeCompo.bind(this);

    this.showViewCompo = this.showViewCompo.bind(this);
    this.handleViewCompo = this.handleViewCompo.bind(this);

    this.showMultiTable = this.showMultiTable.bind(this);
    this.handleMultiTable = this.handleMultiTable.bind(this);

    this.showChecklistCompo = this.showChecklistCompo.bind(this);
    this.handleChecklistCompo = this.handleChecklistCompo.bind(this);

    this.showInventoryCompo = this.showInventoryCompo.bind(this);
    this.handleInventoryCompo = this.handleInventoryCompo.bind(this);

    this.showForgetPassword = this.showForgetPassword.bind(this);
    this.handleForgetPassword = this.handleForgetPassword.bind(this);

    this.showGenerateNewPin = this.showGenerateNewPin.bind(this);
    this.handleGenerateNewPin = this.handleGenerateNewPin.bind(this);

    this.showOtpVerification = this.showOtpVerification.bind(this);
    this.handleOtpVerification = this.handleOtpVerification.bind(this);

    this.showNewPassword = this.showNewPassword.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);

    this.showClientInfo = this.showClientInfo.bind(this);
    this.handleClientInfo = this.handleClientInfo.bind(this);

    this.showClientInfoRec = this.showClientInfoRec.bind(this);
    this.handleClientInfoRec = this.handleClientInfoRec.bind(this);

    this.showRoleSelectionCompo = this.showRoleSelectionCompo.bind(this);
    this.handleRoleSelectionCompo = this.handleRoleSelectionCompo.bind(this);

    this.showHtmlPage = this.showHtmlPage.bind(this);
    this.handleHtmlPage = this.handleHtmlPage.bind(this);

    this.showNotificationCompo = this.showNotificationCompo.bind(this);
    this.handleNotificationCompo = this.handleNotificationCompo.bind(this);

    /*    this.sendLandingPref = this.sendLandingPref.bind(this); */
    this.handleSendLandingPref = this.handleSendLandingPref.bind(this);

    this.showUiPageCompo = this.showUiPageCompo.bind(this);
    this.handleUiPageCompo = this.handleUiPageCompo.bind(this);

    this.showVariableCompo = this.showVariableCompo.bind(this);
    this.handleVariableCompo = this.handleVariableCompo.bind(this);

    this.showStatusCompo = this.showStatusCompo.bind(this);
    this.handleStatusCompo = this.handleStatusCompo.bind(this);

    this.showTabularReport = this.showTabularReport.bind(this);
    this.handleTabularReport = this.handleTabularReport.bind(this);

    this.showViewTabularReport = this.showViewTabularReport.bind(this);
    this.handleViewTabularReport = this.handleViewTabularReport.bind(this);

    this.showMaterialCompo = this.showMaterialCompo.bind(this);
    this.handleMaterialCompo = this.handleMaterialCompo.bind(this);

    this.showIncomeExpense = this.showIncomeExpense.bind(this);
    this.handleIncomeExpense = this.handleIncomeExpense.bind(this);

    this.showWorkerInfo = this.showWorkerInfo.bind(this);
    this.handleWorkerInfo = this.handleWorkerInfo.bind(this);

    this.showViewSalary = this.showViewSalary.bind(this);
    this.handleViewSalary = this.handleViewSalary.bind(this);

    this.showErrorCompo = this.showErrorCompo.bind(this);
    this.handleErrorCompo = this.handleErrorCompo.bind(this);

    this.showInitialSetupCompo = this.showInitialSetupCompo.bind(this);
    this.handleInitialSetupCompo = this.handleInitialSetupCompo.bind(this);

    this.showUserInfoCompo = this.showUserInfoCompo.bind(this);
    this.handleUserInfoCompo = this.handleUserInfoCompo.bind(this);

    this.callhome = this.callhome.bind(this);
    this.logout = this.logout.bind(this);
    this.goBack = this.goBack.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openList = this.openList.bind(this);
    this.setImp = this.setImp.bind(this);
    this.closeList = this.closeList.bind(this);
    this.getImpsUserInfo = this.getImpsUserInfo.bind(this);
    this.getInitialData = this.getInitialData.bind(this);
    this.getContextMenu = this.getContextMenu.bind(this);
    this.getLabelContext = this.getLabelContext.bind(this);

    this.storeInHistory = this.storeInHistory.bind(this);
  }

  sendUserDetailsExpo = () => {
    let data = this.state.directExpoToken;
    let data2 = this.state.deviceId;
    let data3 = this.state.version;
    let data4 = this.state.build;
    var token = localStorage.getItem("token");
    if (data) {
      // alert(directExpoToken);
      axios
        .post(
          this.state.loca + "/loom/set/sendexpotoken",
          { expoToken: data, deviceId: data2, version: data3, build: data4 },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          }
        )
        .then((resp) => {
          const response = resp.data;

          // Extract expoToken and deviceId from the response object
          const newExpoToken = response.expoToken;
          const newDeviceId = response.deviceId;

          // Store the new expoToken in localStorage
          localStorage.setItem("expoToken", newExpoToken);

          // Optionally store the deviceId if needed
          localStorage.setItem("deviceId", newDeviceId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  async componentDidMount() {
    try {
      if (window.ReactNativeWebView) {
        const tokenRequest = (tkn) => {
          console.log("token request running...");
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: "reqExpoToken" })
            );
            // sendUserDetailsExpo(tkn)
          }
        };

        tokenRequest();

        if (window.ReactNativeWebView) {
          window.addEventListener("message", (event) => {
            const data = event.data;

            this.setState({ newData: data.payload });
            // console.log(data);
            // console.log("data", data);
            if (data.action === "GREETING") {
              localStorage.setItem("localToken", data.payload);
              this.setState({
                directExpoToken: data.payload,
                deviceId: data.deviceId,
                version: data.version,
                build: data.build,
              });
              // localStorage.setItem("expoToken", data.payload);
            }
          });
        }
      }
      const response = await this.verifyRecord().then((res) => {
        return res;
      });
      console.log("verifyRecord response:", response);
      console.log("verifyRecord response:", response);
      if ( response === "fail" || response === "not_verified" || !response) {
        localStorage.setItem("token", "");
        this.setState({ showlogin: true, loading: false });
        this.setAuthenticated(3);
        this.loading.current = true;
      }
    } catch (error) {
      console.error("Error in componentDidMount:", error);
    }
  }

  checkIsMobile=()=>{
    const { innerWidth: width, innerHeight: height } = window;
    var wd = width;
let mobile=false;
    if (wd < 768) {
      mobile = true;
    }
    this.setIsMobile(mobile);
  }
  subscribeToTopic = (directExpoToken) => {
    var token = localStorage.getItem("token");
    let data = this.state.directExpoToken;
    let topic = "loomyarn";
    let test = localStorage.getItem("localToken");
    axios
      .post(
        this.state.loca + "/firebase/subscribe",
        { recipientToken: test, topic: topic },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        localStorage.setItem("expoTokenTopic", directExpoToken);
        localStorage.setItem("topicCalled", "true");
        console.log(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  verifyRecord = async () => {
    // this.setState({loading: true});
    var token = localStorage.getItem("token");
    if (window.ReactNativeWebView) {
      this.sendUserDetailsExpo();
      let isTokenStored = localStorage.getItem("topicCalled");
      if (!isTokenStored) {
        this.subscribeToTopic();
      }
    }
    if (token !== "" && token !== null) {
      try {
        const res = await axios.get(this.state.loca + "/verify", {
          headers: {
            authorization: "Bearer " + token,
          },
        });
        console.log(res);
        console.log("tar", res.status);
        console.log("role", res.data[3].role);
        if (res.status === 200) {
          var userInfo = {
            userId: res.data[3].userId,
            username: res.data[3].username,
            name: res.data[3].name,
            admin: res.data[3].admin,
            cid: res.data[3].cid,
            mobileNumber: res.data[3].mobileNumber,
            role: res.data[3].role,
          };
          var rsp = res.data[0].response;
          this.checkIsMobile();
          if (rsp === "verified" || rsp === "refereshed") {
            if (rsp === "verified") {
              if (this._isMounted) {
                this.setState({ showlogin: false });
              }
              this.setState({ showlogin: false });
              localStorage.setItem("userDetails", JSON.stringify(userInfo));
              this.dismiss();
              this.setAuthenticated(1);
              this.loading.current = true;
              return rsp;
            } else if (rsp === "refereshed") {
              localStorage.setItem("token", res.data[1].token);
              this.setState({ showlogin: false });

              localStorage.setItem("userDetails", JSON.stringify(userInfo));

              this.dismiss();
              this.setAuthenticated(1);
              this.loading.current = true;
              return rsp;
            }
          } else if (rsp === "fail" || rsp === "not_verified") {
            localStorage.setItem("token", "");
            this.setState({ showlogin: true, loading: false });
            this.setAuthenticated(3);
            this.loading.current = true;
            return rsp;
          }
        } else {
          this.setState({ showlogin: true, loading: false });
          this.setAuthenticated(3);
          this.loading.current = true;
          return "not verified";
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.setItem("token", "");
            if (
              error.response.data.cause.startsWith(
                "io.jsonwebtoken.ExpiredJwtException: JWT expired at "
              )
            ) {
              this.setState({ showlogin: true, loading: false });
              this.setAuthenticated(3);
              this.loading.current = true;
            }
            this.setState({ showlogin: true, loading: false });
            this.setAuthenticated(3);
            this.loading.current = true;
          }
        } else if (error.request) {
          this.loading.current = true;
        } else {
          this.loading.current = true;
        }
      }
    } else {
      this.setState({ showlogin: true, loading: false });
      this.setAuthenticated(3);
      this.loading.current = true;
      return "no token";
    }
  };

  dismiss = async () => {
    if (this._isMounted) {
      this.setState({ loading: false });
    }

    // this.props.showDash();
    console.log("userDetails", localStorage.getItem("userDetails"));
    this.showMainCompo(JSON.parse(localStorage.getItem("userDetails")));
    await this.getContextMenu();
    // console.log("tar446", this.state.newData);
    // console.log("tar446", this.state.newData);
    // await this.getContextMenuAndLabel();
  };

  componentWillUnmount() {
    // Clean up event listener
    setTimeout(() => {}, 5000);
    // localStorage.setItem("abc", JSON.stringify(this.state.backMethod));
    /*   handleResize() {
          this.setState({ pageHeight: window.innerHeight });
        } */
  }

  registerCompo(lang, rtype) {
    this.showLoginFunction(false, true, false, false, false);
    this.setState({
      language: lang,
      registerType: rtype,
    });
  }

  setImp(rel) {
    this.setState({ reload: rel });
    window.location.reload(false);
  }

  // showLogin() {
  //   this.setState({ showlogin: true });
  // }
  setIsMobile = (val) => {
    this.setState({ isMobile: val });
    console.log(this.state.isMobile);
  };

  // setheadHeight = (val) => {
  //   console.log("abd541", this.state.headHeight, val);
  //   this.setState({ headHeight: val });
  // };

  setFormHeight = (val) => {
    console.log("abd575", this.state.formHeight, val);
    this.setState({ formHeight: val });
  };

  showLogin(val) {
    if (val != null) {
      this.setState({ regis: val });
    }
    this.showLoginFunction(true, false, false, false, false, false, false);
  }

  storeInHistory(obj, methodName) {
    this.setState((prevState) => {
      const updatedMap = new Map(prevState.back);
      updatedMap.set(this.state.counter1, obj);
      return { back: updatedMap };
    });
    this.setState((prevState) => {
      const updatedMap = new Map(prevState.backMethod);
      updatedMap.set(this.state.counter1, methodName);
      return { backMethod: updatedMap };
    });
    this.setState({
      counter1: this.state.counter1 + 1,
      counter2: this.state.counter1 + 1,
    });
    setTimeout(() => {}, 1000);
  }

  callhome() {
    var lan_type = this.state.landingPref.type;
    console.log(lan_type);
    if (lan_type === undefined) {
      if (lan_type === "dashboard") {
        // this.showDashboard();
      } else if (lan_type === "report") {
        var rt_name = this.state.landingPref.report.value;
        this.showReportCompo(rt_name);
        this.showReportCompoNew();
      } else if (lan_type === "checklist") {
        this.showChecklistCompo();
      } else if (lan_type === "uipage") {
        this.showUiPageCompo("initial");
        // this.showHtmlPage(
        //   {
        //     filter: [
        //       {
        //         co: "name",
        //         cl: "Name",
        //         mc: "=",
        //         an: "uipage",
        //         ct: "String",
        //         af: "",
        //         rf: {
        //           id: "",
        //           value: "",
        //         },
        //       },
        //     ],
        //   },
        //   this.state.record
        // );
      } else {
      }
    } else {
    }
  }

  showReportCompo(name, bck) {
    this.setState({
      report_name: name,
    });
  }

  setLandingPref = (lanPref) => {
    this.setState({ landingPref: lanPref });
  };

  showReportCompoNew(nam, bck) {
    var isMob = this.state.isMobile ? false : true;

    this.setState({
      report_name: nam,
      showLeftPane: isMob,
    });
  }

  showSortCompo(tab, url, name) {
    this.setState({
      sorted_name: tab,
      url: url,
      taskName: name,
    });
  }

  showListCompo(name, fil, tm) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: name,
      filter: fil,
      timeline: tm,
      showLeftPane: isMob,
    });
  }

  showOtherCompo(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
    if ("report") {
    }
  }

  showFormCompo(nam, recordid, ty) {
    var isMob = this.state.isMobile ? false : true;

    this.setState({
      tabname: nam,
      rid: recordid,
      formRecordType: ty,
      showLeftPane: isMob,
      showNotificationCompo: false,
    });
  }

  showPropCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showMultiCompo(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: nam,
      showLeftPane: isMob,
    });
  }

  showMultiPage(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: nam,
      showLeftPane: isMob,
    });
  }

  showClientReg(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: nam,
      showLeftPane: isMob,
    });
  }

  showOwnerPrefComp(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: nam,
      showLeftPane: isMob,
    });
  }

  showThemeCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showViewCompo(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: nam,
      showLeftPane: isMob,
    });
  }

  showMultiTable(nam) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      mt_name: nam,
      showLeftPane: isMob,
    });
  }

  showChecklistCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showInventoryCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      // filter: fil,
      showLeftPane: isMob,
    });
  }

  showClientInfo(fil) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      filter: fil,
      showLeftPane: isMob,
    });
  }

  showClientInfoRec(nam, recordid) {
    this.setState({
      tabname: nam,
      rid: recordid,
    });
  }

  showRoleSelectionCompo(id, rt) {
    this.setState({
      i_d: id,
      rt: rt,
    });
  }

  showHtmlPage(filter, record) {
    this.setState({ filter: filter, record: record });
  }

  showUiPageCompo(name) {
    this.setState({ name: name });
  }

  showVariableCompo(filter) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      filter: filter,
      showLeftPane: isMob,
    });
  }

  showStatusCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showTabularReport(id) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
      rid: id,
    });
  }

  showViewTabularReport(name, fil, tm) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      tabname: name,
      filter: fil,
      timeline: tm,
      showLeftPane: isMob,
    });
  }

  showMaterialCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showIncomeExpense() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showWorkerInfo(id) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
      rid: id,
    });
  }

  showViewSalary(id) {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
      rid: id,
    });
  }

  showErrorCompo(error) {
    var isMob = this.state.isMobile ? false : true;
    if (error === undefined) {
      this.setState({ location: "null" });
    } else {
      this.setState({ location: error });
    }
    this.setState({
      showLeftPane: isMob,
    });
  }

  showInitialSetupCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }

  showUserInfoCompo() {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  }
  hideLeftPanForMob = () => {
    var isMob = this.state.isMobile ? false : true;
    this.setState({
      showLeftPane: isMob,
    });
  };

  showRegister() {
    this.showLoginFunction(false, true, false, false, false, false, false);
  }

  showForgetPassword() {
    this.showLoginFunction(false, false, true, false, false, false, false);
  }

  showGenerateNewPin() {
    this.showLoginFunction(false, false, false, true, false, false, false);
  }

  showOtpVerification() {
    this.showLoginFunction(false, false, false, false, true, false, false);
  }

  showNewPassword(op, un) {
    this.setState({
      op: op,
      un: un,
    });
    this.showLoginFunction(false, false, false, false, false, true, false);
  }

  showMainCompo(userinfo) {
    this.setState({ userinfo: userinfo });
    this.showLoginFunction(false, false, false, false, false, false, true);
    this.getImpsUserInfo();
  }

  showNotificationCompo(cnt,val) {
    console.log("being called");
    
    if(val==="false"){

      this.setState({
        
        showNotificationCompo: false,
      });
    }else{
      this.setState({
        cnt: cnt,
        showNotificationCompo: true,
      });
    }
   
  }

  showLoginFunction(
    login,
    register,
    forget,
    newpin,
    otpvrf,
    newpass,
    maincompo
  ) {
    this.setState({
      showLoginCompo: login,
      showRegister: register,
      showForgetPassword: forget,
      showGenerateNewPin: newpin,
      showOtpVerification: otpvrf,
      showNewPassword: newpass,
      // showMainCompo: maincompo,
    });
  }

  handleDashboard() {
    this.setState({ callDash: false });
  }

  handleListCompo() {
    this.setState({ showListCompo: false });
  }

  handleOtherCompo() {
    this.setState({ showOtherCompo: false });
  }

  handleSortCompo() {
    this.setState({ showSortedCompo: false });
  }

  handleMultiCompo() {
    this.setState({ showMultiCompo: false });
  }

  handleFormCompo() {
    this.setState({ showFormCompo: false });
  }

  handleMultiPage() {
    this.setState({ showMultiPage: false });
  }

  handleClientReg() {
    this.setState({ showClientReg: false });
  }

  handleNewPassword() {
    this.setState({ showNewPassword: false });
  }

  handleOtpVerification() {
    this.setState({ showOtpVerification: false });
  }

  handleGenerateNewPin() {
    this.setState({ showGenerateNewPin: false });
  }

  handleForgetPassword() {
    this.setState({ showForgetPassword: false });
  }

  handleViewCompo() {
    this.setState({ showViewCompo: false });
  }

  handleMultiTable() {
    this.setState({ showMultiTable: false });
  }

  handleThemeCompo() {
    this.setState({ showThemeCompo: false });
  }

  handleChecklistCompo() {
    this.setState({ showChecklistCompo: false });
  }

  handleInventoryCompo() {
    this.setState({ showInventoryCompo: false });
  }

  handleInitialSetupCompo() {
    this.setState({ showInitialSetupCompo: false });
  }

  handleUserInfoCompo() {
    this.setState({ showUserInfoCompo: false });
  }

  handleOwnerPrefComp() {
    this.setState({ showOwnerPrefComp: false });
  }

  handleReportCompo() {
    this.setState({ showReportCompo: false });
  }

  handleReportCompoNew() {
    this.setState({ showReportCompoNew: false });
  }

  handleRegisterCompo() {
    this.setState({ showRegister: false });
  }

  handleClientInfo() {
    this.setState({ showClientInfo: false });
  }

  handleClientInfoRec() {
    this.setState({ showClientInfoRec: false });
  }

  handleRoleSelectionCompo() {
    this.setState({ showRoleSelectionCompo: false });
  }

  handleHtmlPage() {
    this.setState({ showHtmlPage: false });
  }

  handleSendLandingPref() {
    this.setState({ sendLandingPref: false });
  }

  handleUiPageCompo() {
    this.setState({ showUiPageCompo: false });
  }

  handleNotificationCompo() {
    this.setState({ showNotificationCompo: false });
  }

  handleVariableCompo() {
    this.setState({ showVariableCompo: false });
  }

  handleStatusCompo() {
    this.setState({ showStatusCompo: false });
  }

  handleTabularReport() {
    this.setState({ showTabularReport: false });
  }

  handleViewTabularReport() {
    this.setState({ showViewTabularReport: false });
  }

  handleMaterialCompo() {
    this.setState({ showMaterialCompo: false });
  }

  handleIncomeExpense() {
    this.setState({ showIncomeExpense: false });
  }

  handleWorkerInfo() {
    this.setState({ showWorkerInfo: false });
  }

  handleViewSalary() {
    this.setState({ showViewSalary: false });
  }

  handleErrorCompo() {
    this.setState({ showErrorCompo: false });
  }

  handleLeftNavCompo() {
    this.setState({
      showListCompo: false,
      showFormCompo: false,
      callDash: false,
      showSortedCompo: false,
      showPropCompo: false,
    });
    window.scroll({ top: 0, behavior: "smooth" });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("tableName");

    this.setState({
      showlogin: true,
      showRegister: false,
      isOpen: false,
      showLoginCompo: true,
    });
  }

  goBack() {
    this.setState({ showRegister: false, showLoginCompo: true });
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  openList() {
    this.setState({ adminPanel: true });
  }

  closeList() {
    this.setState({ adminPanel: false });
  }
  handleLeftPane = (val) => {
    console.log("abd1109", val);
    this.setState({ showLeftPane: val });
  };

  componentDidUpdate(prevProps, prevState) {
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (isAndroid && window.ReactNativeWebView) {
      console.log("Disable refresh rate for native is working...");
      if (prevState.showLeftPane !== this.state.showLeftPane) {
        const leftComponentOpen = !this.state.showLeftPane ? false : true;
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ leftComponentOpen: leftComponentOpen })
        );
      }
    }
  }

  async getImpsUserInfo() {
    var token = localStorage.getItem("token");
    await axios
      .get(this.state.loca + "/loom/get/userinfo/record", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const record = resp.data;
        console.log(record);
        if (record !== null && record !== "" && record !== "undefined") {
          this.setState({ impersonateInfo: record });
          this.setState({ setRecord: record });
          if (record.admin === "true") {
            this.setState({ impersonate_name: record.adminClient.value });
          } else if (record.client.value !== null && record.user === "false") {
            if (window.screen.width > 500) {
              console.log("big window");
              this.setState({
                isImpersonate: true,
                impersonate_name: "Impersonated With " + record.client.value,
              });
            } else {
              const nameParts = record.client.value.split(" "); // Split the string at the first space
              const firstName = nameParts[0];
              this.setState({
                // impersonate_name: "IM " + record.client.value,
                isImpersonate: true,
                impersonate_name: "IM " + firstName,
              });
            }
          } else if (
            record.user === "true" &&
            record.impersonateUser.value !== null
          ) {
            if (window.screen.width > 500) {
              this.setState({
                isImpersonate: true,
                impersonate_name:
                  "Impersonated With " + record.impersonateUser.value,
              });
            } else {
              const nameParts = record.impersonateUser.value.split(" "); // Split the string at the first space
              const firstName = nameParts[0];
              this.setState({
                // impersonate_name: "IM " + record.impersonateUser.value,
                isImpersonate: true,
                impersonate_name: "IM " + firstName,
              });
            }
          }
        }
      });
    this.getInitialData().then((data) => {});
  }

  async getInitialData() {
    var token = localStorage.getItem("token");
    await axios
      .get(this.state.loca + "/loom/get/Notification", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let result = resp.data;
        console.log(result);
        //  result=JSON.parse(result)
        this.setState({
          //"notification":{"messages":
          msg: result.notification?.messages,
          cnt: result.notification?.count,
        });
      });
  }

  getContextMenu = async () => {
    try {
      var token = localStorage.getItem("token");
      await axios
        .get(this.state.loca + "/fetch/contextMenu", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          this.setState({
            contextMenu: resp.data,
            loading: false,
          });
        });
      await this.getLabelContext();
    } catch (error) {}
  };

  getLabelContext = async () => {
    try {
      console.log("abd1270");
      var token = localStorage.getItem("token");
      await axios
        .get(this.state.loca + "/fetch/labelContext", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            labelContext: resp.data,
            loading: false,
          });
        });
    } catch (error) {
      console.log("Error fetching context menu:", error);
    }
  };

  setAuthenticated = (val) => {
    console.log("stt", val);
    this.setState({ authenticated: val, loading: false });
    this.loading.current = true;
  };


  // componentDidUpdate(prevProps, prevState) {}

  render() {
    const { isImpersonate } = this.state;
    console.log(isImpersonate);
    if (this.loading.current) {
      return (
        <GlobalContext.Provider
          value={{
            loca: this.state.loca,
            language: this.state.language,
            height: this.state.height,
            showLeftPane: this.state.showLeftPane,
            userinfo: this.state.userinfo,
            impersonate_name: this.state.impersonate_name,
            isImpersonate: isImpersonate,
            openModal: this.openModal,
            isOpen: this.state.isOpen,
            setAuthenticated: this.setAuthenticated,
            closeModal: this.closeModal,
            showNotificationCompo: this.showNotificationCompo,
            handleNotificationCompo: this.handleNotificationCompo,
            showNoti: this.state.showNotificationCompo,
            msg: this.state.msg,
            cnt: this.state.cnt,
            openList: this.openList,
            closeList: this.closeList,
            adminPanel: this.state.adminPanel,
            setRecord: this.state.setRecord,
            contextMenu: this.state.contextMenu,
            setImp: this.setImp,
            verifyRecord: this.verifyRecord,
            dismiss: this.dismiss,
            landingPref: this.state.landingPref,
            setLandingPref: this.setLandingPref,
            filter: this.state.filter,
            isMobile: this.state.isMobile,
            newData: this.state.newData,
            // headHeight: this.state.headHeight,
            // setheadHeight: this.setheadHeight,
            formHeight: this.state.formHeight,
            setFormHeight: this.setFormHeight
          }}
        >
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={this.state.authenticated == 1 ? "/private" : "/login"}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <Login
                    language={this.state.language}
                    showRegister={(lang, rtype) =>
                      this.registerCompo(lang, rtype)
                    }
                    showForgetPassword={() => this.showForgetPassword()}
                    showNewPassword={(op, un) => this.showNewPassword(op, un)}
                    showMainCompo={(userinfo) => this.showMainCompo(userinfo)}
                    reg={this.state.regis}
                    loca={this.state.loca}
                    setAuthenticated={(val) => this.setAuthenticated(val)}
                    verifyRecord={this.verifyRecord}
                    dismiss={this.dismiss}
                    showlogin={this.state.showlogin}
                  ></Login>
                }
              />
              <Route
                path="/forgetpassword"
                element={
                  <ForgetPassword
                    isMobile={this.state.isMobile}
                    unmountMe={this.handleForgetPassword}
                    showLogin={() => this.showLogin()}
                    showOtpVerification={() => this.showOtpVerification()}
                    loca={this.state.loca}
                  ></ForgetPassword>
                }
              />
              <Route
                path="/otpvarification"
                element={
                  <OtpVerification
                    isMobile={this.state.isMobile}
                    unmountMe={this.handleOtpVerification}
                    showForgetPassword={() => this.showForgetPassword()}
                    showGenerateNewPin={() => this.showGenerateNewPin()}
                    loca={this.state.loca}
                    showLogin={(val) => this.showLogin(val)}
                  ></OtpVerification>
                }
              />
              <Route
                path="/generatenewpin"
                element={
                  <GenerateNewPin
                    isMobile={this.state.isMobile}
                    unmountMe={this.handleGenerateNewPin}
                    showLogin={() => this.showLogin()}
                    loca={this.state.loca}
                  ></GenerateNewPin>
                }
              />
              <Route
                path="/register"
                element={
                  <RegisterComponent
                    isMobile={this.state.isMobile}
                    unmountMe={this.handleRegisterCompo}
                    showLogin={this.showLogin}
                    // login={this.state.showlogin}
                    // register={this.state.showRegister}
                    type={this.state.registerType}
                    language={this.state.language}
                    loca={this.state.loca}
                    showOtpVerification={() => this.showOtpVerification()}
                  ></RegisterComponent>
                }
              />
              <Route
                path="/setpassword"
                element={
                  <SetNewPassword
                    isMobile={this.state.isMobile}
                    unmountMe={this.handleNewPassword}
                    showLogin={() => this.showLogin()}
                    /*   op={this.state.op} */
                    /*   un={this.state.un} */
                    loca={this.state.loca}
                  ></SetNewPassword>
                }
              />

              {this.state.authenticated === 1 && (
                <>
                  <Route
                    path="/private"
                    element={
                      <PrivateCompo
                        showListCompo={(nm, fil, tm) =>
                          this.showListCompo(nm, fil, tm)
                        }
                        showFormCompo={(nm, recordid, ty) =>
                          this.showFormCompo(nm, recordid, ty)
                        }
                        /*   height={this.state.height} */
                        showOtherCompo={(nm) => this.showOtherCompo(nm)}
                        showOwnerPrefComp={(nm) => this.showOwnerPrefComp(nm)}
                        showThemeCompo={(nm) => this.showThemeCompo(nm)}
                        showMultiCompo={(nm) => this.showMultiCompo(nm)}
                        showMultiPage={(nm) => this.showMultiPage(nm)}
                        showClientReg={(nm) => this.showClientReg(nm)}
                        showViewCompo={(nm) => this.showViewCompo(nm)}
                        showMultiTable={(nm) => this.showMultiTable(nm)}
                        showClientInfo={(nm) => this.showClientInfo(nm)}
                        /*   sendLandingPref={(lanpref) => this.sendLandingPref(lanpref)} */
                        showUiPageCompo={(name) => this.showUiPageCompo(name)}
                        showPropCompo={() => this.showPropCompo()}
                        showChecklistCompo={() => this.showChecklistCompo()}
                        showInventoryCompo={() => this.showInventoryCompo()}
                        showInitialSetupCompo={() =>
                          this.showInitialSetupCompo()
                        }
                        showReportCompoNew={this.showReportCompoNew}
                        // nam, recordid, ty
                        showStatusCompo={this.showStatusCompo}
                        showTabularReport={this.showTabularReport}
                        showViewTabularReport={(nm, fil, tm) =>
                          this.showViewTabularReport(nm, fil, tm)
                        }
                        showUserInfoCompo={this.showUserInfoCompo}
                        showVariableCompo={(fil) => this.showVariableCompo(fil)}
                        showMaterialCompo={this.showMaterialCompo}
                        showIncomeExpense={this.showIncomeExpense}
                        showWorkerInfo={this.showWorkerInfo}
                        showViewSalary={this.showViewSalary}
                        unmountMe={this.handleLeftNavCompo}
                        handleLeftPane={(val) => this.handleLeftPane(val)}
                        loca={this.state.loca}
                        isMobile={this.state.isMobile}
                        userinfo={this.state.userinfo}
                        hideLeftPanForMob={this.hideLeftPanForMob}
                        showLeftPane={this.state.showLeftPane}
                        setIsMobile={this.setIsMobile}
                      />
                    }
                  >
                    <Route
                      path="dashboard"
                      element={
                        <Dashboard
                          myDash={true}
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></Dashboard>
                      }
                    />
                    <Route
                      path="ui-page"
                      element={
                        <UiPageComponent
                          name={this.state.name}
                          // showClientInfo={(fil) => this.showClientInfo(fil)}
                          showVariableCompo={(fil) =>
                            this.showVariableCompo(fil)
                          }
                          unmountMe={this.handleUiPageCompo}
                          loca={this.state.loca}
                        ></UiPageComponent>
                      }
                    />
                    <Route
                      path="var"
                      element={
                        <UiPageComponent
                          name={this.state.name}
                          // showClientInfo={(fil) => this.showClientInfo(fil)}
                          showVariableCompo={(fil) =>
                            this.showVariableCompo(fil)
                          }
                          unmountMe={this.handleUiPageCompo}
                          loca={this.state.loca}
                        ></UiPageComponent>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <ListComponent
                          unmountMe={this.handleListCompo}
                          listName={this.state.tabname}
                          timeline={this.state.timeline}
                          filter={this.state.filter}
                          showFormCompo={(nm, rid, ty) =>
                            this.showFormCompo(nm, rid, ty)
                          }
                          ty={this.state.formRecordType}
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          contextMenu={this.state.contextMenu}
                          labelContext={this.state.labelContext}
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          showViewCompo={this.showViewCompo}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></ListComponent>
                      }
                    />

                    <Route
                      path="form"
                      element={
                        <FormComponent
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          unmountMe={this.handleFormCompo}
                          tabname={this.state.tabname}
                          rid={this.state.rid}
                          ty={this.state.formRecordType}
                          isMobile={this.state.isMobile}
                          showFormCompo={(nm, rid, ty) =>
                            this.showFormCompo(nm, rid, ty)
                          }
                          showClientInfoRec={(nm, rid) =>
                            this.showClientInfoRec(nm, rid)
                          }
                          i_d={this.state.i_d}
                          showRoleSelectionCompo={(id, rt) =>
                            this.showRoleSelectionCompo(id, rt)
                          }
                          showMainCompo={(userinfo) =>
                            this.showMainCompo(userinfo)
                          }
                          showHtmlPage={(filter, record) =>
                            this.showHtmlPage(filter, record)
                          }
                          loca={this.state.loca}
                          // contxtMenu={this.state.contxtMenu}
                          contextMenu={this.state.contextMenu}
                          labelContext={this.state.labelContext}
                          showViewCompo={this.showViewCompo}
                          showStatusCompo={this.showStatusCompo}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                          showNotificationCompo={(cnt) =>
                            this.showNotificationCompo(cnt)
                          }

                          // showIncomeExpense={this.showIncomeExpense}
                        ></FormComponent>
                      }
                    />

                    <Route
                      path="multi-insert"
                      element={
                        <MultiInsert
                          unmountMe={this.handleMultiCompo}
                          tabName={this.state.tabname}
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></MultiInsert>
                      }
                    />
                    <Route
                      path="multi-app_request"
                      element={
                        <MultipleApplicationRequest
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></MultipleApplicationRequest>
                      }
                    />

                    <Route
                      path="setup-view"
                      element={
                        <FormViewComponent
                          unmountMe={this.handleViewCompo}
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          /*  listname={this.state.tabname} */
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></FormViewComponent>
                      }
                    />
                    <Route
                      path="dash-Info"
                      element={
                        <UserInfo
                          /*  unmountMe={this.handleUserInfoCompo} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          /*   showFormCompo={(nm, recordid, ty) =>
               this.showFormCompo(nm, recordid, ty)
             } */
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></UserInfo>
                      }
                    />
                    <Route
                      path="test"
                      element={<CheckList_CompoNew></CheckList_CompoNew>}
                    />
                    <Route
                      path="role-select"
                      element={
                        <RoleSelectionComponent
                          i_d={this.state.i_d}
                          rt={this.state.rt}
                          /*  unmountMe={this.handleRoleSelectionCompo} */
                          loca={this.state.loca}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></RoleSelectionComponent>
                      }
                    />

                    <Route
                      path="report"
                      element={
                        <ReportComponentNew
                          reportName={this.state.report_name}
                          /*  unmountMe={this.handleReportCompoNew} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></ReportComponentNew>
                      }
                    />
                    <Route
                      path="multi-page"
                      element={
                        <Multipage
                          /*        unmountMe={this.handleMultiPage} */
                          /*   tabname={this.state.tabname} */
                          /*      showListCompo={(nm, fil, tm) =>
                   this.showListCompo(nm, fil, tm)
                 } */
                          loca={this.state.loca}
                        ></Multipage>
                      }
                    />
                    <Route
                      path="inventory"
                      element={
                        <InventoryComponent
                          /*    unmountMe={this.handleInventoryCompo} */
                          loca={this.state.loca}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></InventoryComponent>
                      }
                    />
                    <Route
                      path="set-material"
                      element={
                        <MaterialComponent
                          /*   unmountMe={this.handleMaterialCompo} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          userInfo={this.state.userinfo}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></MaterialComponent>
                      }
                    />
                    <Route
                      path="view-tabular-report"
                      element={
                        <ViewTabularReport
                          unmountMe={this.handleViewTabularReport}
                          loca={this.state.loca}
                          /*    listName={this.state.tabname} */
                          /*   timeline={this.state.timeline} */
                          /*   filter={this.state.filter} */
                          isMobile={this.state.isMobile}
                          showTabularReport={(id) => this.showTabularReport(id)}
                          showWorkerInfo={(id) => this.showWorkerInfo(id)}
                          showViewSalary={(id) => this.showViewSalary(id)}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></ViewTabularReport>
                      }
                    />
                    <Route
                      path="view-report"
                      element={
                     /*    <TabularReportCompo
                       
                          loca={this.state.loca}
                        
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></TabularReportCompo> */
                        <TabularReportNew   loca={this.state.loca}
                        
                        showErrorCompo={(error) => this.showErrorCompo(error)}>

                        </TabularReportNew>
                      }
                    />
                    <Route
                      path="view-salary"
                      element={
                        <SalaryCompo
                          /*     unmountMe={this.handleViewSalary} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          /* recordId={this.state.rid} */
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></SalaryCompo>
                      }
                    />
                    <Route
                      path="view-worker-info"
                      element={
                        <WorkerInfoCompo
                          /* unmountMe={this.handleWorkerInfo} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          /*   recordId={this.state.rid} */
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></WorkerInfoCompo>
                      }
                    />
                    <Route
                      path="loom-status"
                      element={
                        <LoomStatusComponent
                          /*   unmountMe={this.handleStatusCompo} */
                          /*     showFormCompo={(nm, rid, ty) =>
                          this.showFormCompo(nm, rid, ty)
                        } */
                          loca={this.state.loca}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></LoomStatusComponent>
                      }
                    />
                    <Route
                      path="income-expense"
                      element={
               /*          <IncomeExpense
                      
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showFormCompo={(nm, recordid, ty) =>
                            this.showFormCompo(nm, recordid, ty)
                          }
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></IncomeExpense> */
                        <IncomeExpense2
                      
                        loca={this.state.loca}
                        isMobile={this.state.isMobile}
                        showFormCompo={(nm, recordid, ty) =>
                          this.showFormCompo(nm, recordid, ty)
                        }
                        showErrorCompo={(error) => this.showErrorCompo(error)}
                      ></IncomeExpense2>
                      }
                    />

                    <Route
                      path="ui-page"
                      element={
                        <UiPageComponent
                          name="initial"
                          showVariableCompo={(fil) =>
                            this.showVariableCompo(fil)
                          }
                          // unmountMe={this.handleUiPageCompo}
                          loca={this.state.loca}
                        ></UiPageComponent>
                      }
                    />

                    <Route
                      path="variable"
                      element={
                        <VarBlankCompo
                          filter={this.state.filter}
                          /*   unmountMe={this.handleVariableCompo} */
                          loca={this.state.loca}
                          userInfo={this.state.userinfo}
                          impersonateInfo={this.state.impersonateInfo}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></VarBlankCompo>
                      }
                    />

                    <Route
                      path="initial-setup"
                      element={
                        <InitialSetupComponent
                          /*   unmountMe={this.handleInitialSetupCompo} */
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                        ></InitialSetupComponent>
                      }
                    />
                    <Route
                      path="view-checklist"
                      element={
                        // <CheckListComponent
                        //   /*  unmountMe={this.handleChecklistCompo} */
                        //   showErrorCompo={(error) => this.showErrorCompo(error)}
                        //   loca={this.state.loca}
                        // ></CheckListComponent>
                        <CheckList_CompoNew
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                          loca={this.state.loca}
                        ></CheckList_CompoNew>
                      }
                    />
                    <Route
                      path="owner-preference"
                      element={
                        <OwnerPreference
                          /*  unmountMe={this.handleOwnerPrefComp} */
                          /* tabName={this.state.tabname} */
                          loca={this.state.loca}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></OwnerPreference>
                      }
                    />
                    <Route
                      path="theme"
                      element={
                        <ThemeDesignComponent
                          /*   unmountMe={this.handleThemeCompo} */
                          loca={this.state.loca}
                          // tabName={this.state.tabname}
                        ></ThemeDesignComponent>
                      }
                    />
                    <Route
                      path="properties"
                      element={
                        <PropertiesComponent
                          loca={this.state.loca}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                        ></PropertiesComponent>
                      }
                    />
                    <Route
                      path="dashboard-table"
                      element={
                        <SortComponent
                          sortedName={this.state.sorted_name}
                          url={this.state.url}
                          showListCompo={(nm, fil, tm) =>
                            this.showListCompo(nm, fil, tm)
                          }
                          showFormCompo={(nm, rid, ty) =>
                            this.showFormCompo(nm, rid, ty)
                          }
                          loca={this.state.loca}
                          isMobile={this.state.isMobile}
                          showErrorCompo={(error) => this.showErrorCompo(error)}
                          callhome={() => this.callhome()}
                          taskName={this.state.taskName}
                        ></SortComponent>
                      }
                    />
                  </Route>
                </>
              )}
            </Routes>
          </Router>
        </GlobalContext.Provider>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MainCompo;
